import React from 'react';
import { Tooltip } from 'react-tooltip';
import { cn } from 'src/common/utils';
import './ReactTooltip.css';

interface ReactTooltipProps {
  id: string;
  className?: string;
}

export const ReactTooltip: React.FC<ReactTooltipProps> = ({ id, className }) => (
  <Tooltip
    id={id}
    className={cn('rounded-[3px] text-white bg-dark px-[10px] py-[8px] text-[12px]', className)}
  />
);
