import React, { useState } from 'react';
import { OnboardingQuestion, OnboardingWrapper, PreviewMobileWrapper } from '../Common';
import { useTranslation } from 'react-i18next';
import { MessengerType } from 'src/common/interfaces';
import { MessengerForm } from './Forms/MessengerForm';
import { useSearchParams } from 'src/common/hooks';
import { WhatsappForm } from './Forms/WhatsappForm';
import { MessengerTutorial } from './Tutorials/MessengerTutorial';
import { WhatsappTutorial } from './Tutorials/WhatsappTutorial';
import { InstagramTutorial } from './Tutorials/InstagramTutorial';
import { InstagramForm } from './Forms/InstagramForm';
import { Logo } from 'src/common/assets';
import { ConnectChannelButton } from 'src/ui/ConnectChannelButton/ConnectChannelButton';
import { useTelegram } from 'src/common/hooks/useTelegram';

interface ConnectionLeftProps {}

const availableChannels = [
  MessengerType.WHATSAPP,
  MessengerType.MESSENGER,
  MessengerType.INSTAGRAM,
  MessengerType.TELEGRAM,
];

export const ConnectionLeft: React.FC<ConnectionLeftProps> = () => {
  const { t } = useTranslation();

  const [selectedChannel, setSelectedChannel] = useState<MessengerType | null>(null);
  const { searchParams, setSearchParams } = useSearchParams();
  const { isLoading, saveTelegramSettings } = useTelegram();

  const onSubmit = () => {
    if (selectedChannel) {
      if (selectedChannel !== MessengerType.TELEGRAM) {
        setSearchParams({ channel: selectedChannel });
      } else {
        saveTelegramSettings();
      }
    }
  };

  const currentChannel = searchParams.get('channel');

  if (currentChannel === MessengerType.MESSENGER) {
    return (
      <Shell
        title={t('onboarding.channels.messenger.title')}
        subtitle={t('onboarding.channels.messenger.subtitle')}
      >
        <MessengerForm />
      </Shell>
    );
  } else if (currentChannel === MessengerType.WHATSAPP) {
    return (
      <Shell
        title={t('onboarding.channels.whatsapp.title')}
        subtitle={t('onboarding.channels.whatsapp.subtitle')}
      >
        <WhatsappForm />
      </Shell>
    );
  } else if (currentChannel === MessengerType.INSTAGRAM) {
    return (
      <Shell
        title={t('onboarding.channels.instagram.title')}
        subtitle={t('onboarding.channels.instagram.subtitle')}
      >
        <InstagramForm />
      </Shell>
    );
  }

  return (
    <OnboardingWrapper
      headerText='onboarding.channels.header'
      subheaderText='onboarding.channels.subheader'
      customSubmitText={t('onboarding.channels.save')}
      blockNextStep
      isLoading={isLoading}
      isSubmitDisabled={!!!selectedChannel}
      onSubmit={() => onSubmit()}
    >
      <div className='flex flex-col gap-[15px] pb-[15px] md:mb-0'>
        {availableChannels.map(channel => (
          <ConnectChannelButton
            key={channel}
            app={channel}
            variant={'solid'}
            onClick={() => {
              setSelectedChannel(channel);
            }}
            isSelected={selectedChannel === channel}
          />
        ))}
      </div>
    </OnboardingWrapper>
  );
};

export const ConnectionRight: React.FC = () => {
  const { searchParams } = useSearchParams();

  const channel = searchParams.get('channel');

  const isWhatsapp = channel === MessengerType.WHATSAPP;
  const isMessenger = channel === MessengerType.MESSENGER;
  const isInstagram = channel === MessengerType.INSTAGRAM;

  if (isWhatsapp) return <WaRight />;

  if (isMessenger) return <MessengerRight />;

  if (isInstagram) return <InstagramRight />;

  return (
    <PreviewMobileWrapper>
      <div className='flex flex-col gap-[42px] w-full items-center justify-center h-full'>
        <div
          className='max-w-[284px] text-3xl text-gray-900 font-bold px-10 relative'
          style={{ fontFamily: 'Unbounded' }}
        >
          <div className='h-[84px] w-[144px]'>
            <Logo />
          </div>
          <div className='absolute w-[500px] h-[520px] -bottom-[225px] -left-[150px] bg-[url("https://anamata-bucket-stg.s3.amazonaws.com/anamata-bucket-stg/channels-icons.png")] bg-no-repeat bg-cover'></div>
        </div>
      </div>
    </PreviewMobileWrapper>
  );
};

const Shell: React.FC<{ title: string; subtitle: string }> = ({ children, title, subtitle }) => {
  const { t } = useTranslation();

  return (
    <div className='sticky top-0'>
      <h1 className='text-gray-900 font-bold  text-center text-[24px]'>{title}</h1>
      <h2 className='!my-4 text-center text-[16px]'>{subtitle}</h2>
      {children}
      <OnboardingQuestion
        text={t('onboarding.common.difficulties', { connection: 'messengers' })}
      />
    </div>
  );
};

const WaRight = () => {
  return (
    <>
      <img
        src='https://anamata-bucket-stg.s3.amazonaws.com/anamata-bucket-stg/whatsapp.png'
        width={86}
        height={86}
        className='text-center mx-auto mt-[50px]'
      />
      <WhatsappTutorial />
    </>
  );
};

const MessengerRight = () => {
  return (
    <>
      <img
        src='https://anamata-bucket-stg.s3.amazonaws.com/anamata-bucket-stg/messenger.png'
        width={86}
        height={86}
        className='text-center mx-auto mt-[50px]'
      />
      <MessengerTutorial />
    </>
  );
};

const InstagramRight = () => {
  return (
    <>
      <img
        src='https://anamata-bucket-stg.s3.amazonaws.com/anamata-bucket-stg/instagram.png'
        width={86}
        height={86}
        className='text-center mx-auto mt-[50px]'
      />
      <InstagramTutorial />
    </>
  );
};
