import React from 'react';

import { useSelector } from 'react-redux';
import { selectUser } from 'src/store/user/user.slice';
import { TabletMenu } from './TabletMenu';
import { cn } from 'src/common/utils';
import { useScreenDetect } from 'src/common/hooks';

const PageTopMenu = ({ title, className }: { title: string; className?: string }) => {
  const user = useSelector(selectUser);
  const { isTargetScreen: isMobile } = useScreenDetect('laptop');

  if (!user) {
    return <></>;
  }

  return (
    <div className={'text-center top-0 sticky py-[15px] px-[15px] mt-[-15px] ml-[-15px] w-[calc(100%+30px)] bg-white lg:bg-transparent lg:static'}>
      <div className={cn('flex items-center lg:items-start px-[15px]', className)}>
        {isMobile && <TabletMenu user={user} />}
        {isMobile && <div className='flex-grow'></div>}
        <h2
          className={cn(
            'text-[18px] md:text-[24px] font-bold text-center lg:text-start lg:ml-0',
            className,
          )}
        >
          {title}
        </h2>
        {isMobile && <div className='flex-grow w-[42px]'></div>}
      </div>
    </div>
  );
};

export { PageTopMenu };
