import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import { Button } from 'src/ui/Button/Button';
import { ProfilePreview } from 'src/ui/ProfilePreview/ProfilePreview';
import { cn } from 'src/common/utils/cn';
import { FaCircleInfo } from 'react-icons/fa6';
import { Trans, useTranslation } from 'react-i18next';
import { MyLinksTab } from 'src/components/MyLinksTab';
import { Link } from 'react-router-dom';
import { PageTopMenu } from 'src/components/PageTopMenu';
import { useOnboardingStatus } from 'src/common/hooks';
import { PreviewModal } from 'src/ui/Modal/ProfileModal';
import { UserInterface } from 'src/store/user/user.slice';

const MyLinksPage = () => {
  const { t, handleBackToOnboarding, isCompleted } = useOnboardingStatus();
  const { user } = useSelector((state: AppStateType) => state.user);

  if (!user) return <></>;

  const brandLogoUrl = user?.expert?.brandLogoUrl;

  return (
    <main className='w-full px-[15px] md:px-[30px] py-[15px] lg:bg-light'>
      <PageTopMenu title={t('myLinks.title')} className='lg:text-start lg:mt-0 px-0 py-0' />
      <div className='mt-[23px] flex flex-col justify-center md:flex-row w-full rounded-[9px]'>
        <div
          className={cn(
            'lg:w-1/2 bg-white lg:border-r border-r-grey-300 p-2 lg:p-[20px] lg:px-[30px] text-center flex flex-col justify-center',
            {
              'flex flex-col justify-between': !isCompleted,
            },
          )}
        >
          {!isCompleted ? (
            <div className='flex flex-col items-center'>
              <h2 className='text-[16px] mt-[42px] max-w-[385px]'>{t('myLinks.empty.title')} </h2>
              <Button
                variant='primary'
                className='mt-[24px]'
                onClick={() => handleBackToOnboarding()}
              >
                {t('myLinks.empty.button')}
              </Button>
            </div>
          ) : (
            <div>
              <div>
                <h2 className='text-[16px]'>{t('myLinks.share')} </h2>
                <div className='flex justify-center lg:hidden'>
                  <BusinessCardDialog user={user} />
                </div>
                {user.expert?.domainName && (
                  <MyLinksTab isPage className='mt-4' domainName={user.expert?.domainName} />
                )}
              </div>
            </div>
          )}
          <div className='mt-[30px] mb-28 lg:mb-0'>
            <Trans
              i18nKey={'myLinks.tip'}
              components={{
                1: <Link to={'/business-card'} className='text-primary' />,
                2: <FaCircleInfo className='text-primary inline mr-2' />,
              }}
            />
          </div>
        </div>
        <div
          className={cn(
            'w-1/2 lg:flex items-center justify-center relative overflow-hidden hidden',
          )}
        >
          <div
            className='absolute w-full h-full blur-[25px] bg-white bg-no-repeat bg-cover'
            style={{ backgroundImage: `url(${brandLogoUrl})` }}
          />
          <div className='py-12'>
            <ProfilePreviewCard user={user} />
          </div>
        </div>
      </div>
    </main>
  );
};

export const ProfilePreviewCard = ({
  user,
  className,
}: {
  className?: string;
  user: UserInterface | null;
}) => {
  const brandLogoUrl = user?.expert?.brandLogoUrl;

  return (
    <ProfilePreview
      clientPreview
      {...{ ...user, ...user?.expert, brandLogoUrl }}
      className={className}
      withCreds
      ignoreHover
      disabled
    />
  );
};

interface BusinessCardProps {
  user: UserInterface | null;
}

const BusinessCardDialog: React.FC<BusinessCardProps> = ({ user }) => {
  const { t } = useTranslation();
  const [open, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)} className='my-6 text-md text-primary'>
        {t('myLinks.seeCard')}
      </Button>
      <PreviewModal
        onClose={() => setIsOpen(false)}
        open={open}
        backgroundImage={user?.expert?.brandLogoUrl}
      >
        <ProfilePreviewCard user={user} />
      </PreviewModal>
    </>
  );
};

export default MyLinksPage;
