import React from 'react';
import { MessageVariant, ChatMessage as ChatMessageT, MessengerType } from 'src/common/interfaces';
import ChatMessage from 'src/ui/ChatMessage/ChatMessage';
import SystemMessage from 'src/ui/ChatSystemMessage/SystemMessage';

const ClientMessage = ({ variant, ...props }: ChatMessageT & { messengerType: MessengerType }) => {
  switch (variant) {
    case MessageVariant.PAYMENT_LINK:
      return (
        <div className='w-full flex justify-end'>
          <SystemMessage {...props} type={variant} date={props.createdAt} className='w-3/4' />
        </div>
      );
    case MessageVariant.PAYMENT_SUCCESS:
      return (
        <div className='w-full flex justify-start'>
          <SystemMessage {...props} type={variant} date={props.createdAt} className='w-3/4' />
        </div>
      );
    case MessageVariant.PAYMENT_REJECTED:
      return (
        <div className='w-full flex justify-start'>
          <SystemMessage {...props} type={variant} date={props.createdAt} className='w-3/4' />
        </div>
      );
    case MessageVariant.CLIENT_APPROVED:
      return (
        <div className='w-full flex justify-end'>
          <SystemMessage {...props} type={variant} date={props.createdAt} className='w-3/4' />
        </div>
      );
    case MessageVariant.CONVERSATION_COMPLETED:
      return (
        <SystemMessage
          type={MessageVariant.CONVERSATION_COMPLETED}
          date={props.createdAt}
          className='w-3/4'
        />
      );
    case MessageVariant.AVAILABLE_CONSULTATIONS_AMOUT_INFO:
      return (
        <div className='w-full flex justify-end'>
          <SystemMessage {...props} type={variant} date={props.createdAt} className='w-3/4' />
        </div>
      );
    case MessageVariant.CONVERSATION_INITIATE_END:
      return (
        <div className='w-full flex justify-end'>
          <SystemMessage {...props} type={variant} date={props.createdAt} className='w-3/4' />
        </div>
      );
    default:
      return (
        <ChatMessage
          attachments={props.attachments}
          messengerType={props.messengerType}
          status={props.status}
          message={props.body}
          date={props.createdAt}
          sender={props.type === 'sent' ? 'expert' : 'client'}
        />
      );
  }
};

export { ClientMessage };
