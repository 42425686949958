import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { useScreenDetect } from 'src/common/hooks';
import { MessengerType } from 'src/common/interfaces';
import { cn } from 'src/common/utils/cn';
import { ROUTES } from 'src/router/routes';
import { Button } from 'src/ui/Button/Button';
import { MessengerIcon } from 'src/ui/MessengerIcon/MessengerIcon';
import { MobileBackButton } from 'src/ui/MobileBackButton/MobileBackButton';
import { useTutorialNavigation } from 'src/common/hooks/useTutorialNavigations';
import { StorageKeysEnum } from 'src/common/enums';
import { removeFromSessionStorage } from 'src/common/utils';

interface CommunicationChannelsHeaderProps {
  messengerType: MessengerType;
  title?: string;
  isChanged?: boolean;
  isSaving: boolean;
  isFormDisabled?: boolean;
  onSave: () => void;
  onDiscard: () => void;
}

type CommunicationChannelsHeaderWrapperProps = Pick<
  CommunicationChannelsHeaderProps,
  'title' | 'messengerType'
> & {
  children?: React.ReactNode;
};

interface CommunicationChannelsWrapperProps extends CommunicationChannelsHeaderProps {
  form: React.ReactNode;
  tutorial?: React.ReactNode;
  activeElements: React.ReactNode;
  isChanged: boolean;
  isFormDisabled: boolean;
}

type CommunicationChannelsWrapperMainProps = Pick<
  CommunicationChannelsWrapperProps,
  'form' | 'tutorial'
>;

type CommunicationChannelsWrapperFooterProps = Pick<
  CommunicationChannelsWrapperProps,
  'isChanged' | 'activeElements'
>;

const CommunicationChannelsHeaderWrapper: React.FC<CommunicationChannelsHeaderWrapperProps> = ({
  children,
  title,
  messengerType,
}) => {
  const { isTargetScreen: isMobile } = useScreenDetect('mobile');
  const { t } = useTranslation();

  if (isMobile) {
    return (
      <div className='flex items-center justify-between p-[15px] bg-white sticky top-0 -mt-[15px] -mx-[15px] px-[15px] z-50'>
        <div className='flex items-center gap-2'>
          <MobileBackButton
            to={`/${ROUTES.COMMUNICATION_CHANNELS}`}
            className='w-[22px] h-[22px]'
            onClick={() => removeFromSessionStorage(messengerType)}
          />
          <h1 className='text-[16px] font-bold'>{title}</h1>
        </div>
        <MessengerIcon messengerType={messengerType} className='w-[36px] h-[36px]' />
      </div>
    );
  }

  return (
    <div className='flex items-center justify-between'>
      <div className='flex gap-2'>
        <MessengerIcon messengerType={messengerType} className='w-[60px] h-[60px]' />
        <div>
          <Link to={`/${ROUTES.COMMUNICATION_CHANNELS}`} className='text-[14px] text-primary'>
            {t('communicationChannels.back')}
          </Link>
          <h1 className='text-[24px] font-bold'>{title}</h1>
        </div>
      </div>
      {children}
    </div>
  );
};

const CommunicationChannelsHeader = ({
  messengerType,
  title,
  onDiscard,
  onSave,
  isChanged,
  isSaving,
  isFormDisabled,
}: CommunicationChannelsHeaderProps) => {
  const { t } = useTranslation();

  return (
    <CommunicationChannelsHeaderWrapper title={title} messengerType={messengerType}>
      <div className='flex gap-4'>
        <Button
          variant='muted'
          className={cn('max-w-[100px] bg-secondary hidden md:inline h-[40px] pt-[8px]', {
            '!hidden': !isChanged,
          })}
          onClick={() => onDiscard?.()}
        >
          {t('common.discard')}
        </Button>
        <Button
          className='h-[40px]'
          variant={'primary'}
          onClick={() => onSave?.()}
          disabled={!isChanged || isSaving || isFormDisabled}
        >
          {isSaving && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
          {t('common.save')}
        </Button>
      </div>
    </CommunicationChannelsHeaderWrapper>
  );
};

const CommunicationChannelsWrapperContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <main className='w-full px-[15px] md:px-[30px] pt-[15px] pb-[0px] sm:py-[15px] md:py-[20px] bg-light relative'>
      {children}
    </main>
  );
};

const CommunicationChannelsWrapperMain: React.FC<CommunicationChannelsWrapperMainProps> = ({
  form,
  tutorial,
}) => {
  const { isTargetScreen } = useScreenDetect('laptop-xs');
  const { clearTutorialInformation } = useTutorialNavigation();

  return (
    <div className='flex flex-col xl:flex-row lg:gap-8 mt-4'>
      <section className='bg-white w-full rounded-lg px-[20px] py-[20px] md:px-[30px] py-[22px] relative'>
        {form}
      </section>
      {!isTargetScreen && tutorial ? (
        <section onWheel={() => clearTutorialInformation()} className='w-full'>
          {tutorial}
        </section>
      ) : null}
    </div>
  );
};

const CommunicationChannelsWrapperFooter: React.FC<CommunicationChannelsWrapperFooterProps> = ({
  activeElements,
  isChanged,
}) => {
  return (
    <div
      className={cn('md:hidden flex gap-2 w-full py-[30px] bg-light', {
        'sticky bottom-0 pt-0 bg-white -mx-[15px] px-[15px] w-[calc(100%+30px)]': isChanged,
      })}
    >
      {isChanged ? (
        <div
          className='absolute h-[25px] bg-swhite -mx-[15px] -top-[25px] w-full'
          style={{
            background: 'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
          }}
        ></div>
      ) : null}
      {activeElements}
    </div>
  );
};

export const CommunicationChannelsWrapper: React.FC<CommunicationChannelsWrapperProps> = ({
  children,
  form,
  tutorial,
  activeElements,
  isChanged,
  ...props
}) => {
  return (
    <CommunicationChannelsWrapperContent>
      <CommunicationChannelsHeader {...props} isChanged={isChanged} />
      <CommunicationChannelsWrapperMain form={form} tutorial={tutorial} />
      <CommunicationChannelsWrapperFooter activeElements={activeElements} isChanged={isChanged} />
    </CommunicationChannelsWrapperContent>
  );
};

export const CommunicationChannelsWrapperLite: React.FC<
  CommunicationChannelsHeaderWrapperProps &
    CommunicationChannelsWrapperFooterProps & {
      form: React.ReactNode;
    }
> = ({ activeElements, isChanged, messengerType, title, form }) => {
  return (
    <CommunicationChannelsWrapperContent>
      <CommunicationChannelsHeaderWrapper messengerType={messengerType} title={title} />
      <CommunicationChannelsWrapperMain form={form} />
      <CommunicationChannelsWrapperFooter activeElements={activeElements} isChanged={isChanged} />
    </CommunicationChannelsWrapperContent>
  );
};

export { CommunicationChannelsHeader };
