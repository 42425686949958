import React, { RefObject, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Label } from 'src/ui/Label/Label';
import { TextInput, Textarea } from 'src/ui/TextInput/TextInput';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/ui/Button/Button';
import { FaCircleInfo } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/store/user/user.slice';
import { MessengerSettingsInputs } from 'src/common/utils/schema/channels-settings.schema';
import { ROUTES } from 'src/router/routes';
import { useTutorialNavigation } from 'src/common/hooks/useTutorialNavigations';
import { OnboardingQuestion } from 'src/pages/Onboarding/Subpages/Common';
import { CopyInput } from 'src/ui/CopyInput/CopyInput';
import { getObjectFromStorage, saveToStorageOnBlur } from 'src/common/utils';
import { MessengerType } from 'src/common/interfaces';

interface MessengerFormProps extends UseFormReturn<MessengerSettingsInputs, any, undefined> {
  buttonRef: RefObject<HTMLButtonElement>;
  onSubmit: (vals: MessengerSettingsInputs) => void;
}

const MessengerForm = ({
  reset,
  buttonRef,
  control,
  handleSubmit,
  formState: { errors },
  onSubmit,
}: MessengerFormProps) => {
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  const { showTutorialInformation } = useTutorialNavigation();

  useEffect(() => {
    const data = getObjectFromStorage<MessengerSettingsInputs>(MessengerType.MESSENGER);
    reset({
      pageAccessToken:
        user?.expert?.settings?.messengerSettings?.pageAccessToken ||
        data?.pageAccessToken ||
        undefined,
      pageId: user?.expert?.settings?.messengerSettings?.pageId || data?.pageId || undefined,
    });
  }, [user]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='space-y-6 sticky top-0'>
      <div className='flex flex-col gap-[30px]'>
        <div className='space-y-2 w-full'>
          <div className='flex items-center justify-between'>
            <Label htmlFor='pageAccessToken'>
              {t('onboarding.channels.messenger.pageAccessToken')}
            </Label>{' '}
            <Button
              className='text-primary'
              type='button'
              onClick={() => {
                showTutorialInformation(ROUTES.MESSENGER_TUTORIAL, '5');
              }}
            >
              <FaCircleInfo className='h-4 w-4' />
              <div className='flex-grow text-left'>
                {t('onboarding.channels.messenger.tip.pageAccessToken')}
              </div>
            </Button>
          </div>
          <Controller
            name='pageAccessToken'
            control={control}
            render={({ field }) => {
              return (
                <Textarea
                  id='pageAccessToken'
                  {...field}
                  onBlur={e => saveToStorageOnBlur(e, MessengerType.MESSENGER)}
                  rows={4}
                  error={errors.pageAccessToken?.message}
                />
              );
            }}
          />
        </div>
        <div className='space-y-2'>
          <div className='flex items-center justify-between'>
            <Label htmlFor='pageId'>{t('onboarding.channels.messenger.pageId')}</Label>
            <Button
              className='text-primary'
              type='button'
              onClick={() => {
                showTutorialInformation(ROUTES.MESSENGER_TUTORIAL, '5');
              }}
            >
              <FaCircleInfo className='h-4 w-4' />
              <div className='flex-grow text-left'>
                {t('onboarding.channels.messenger.tip.pageId')}
              </div>
            </Button>
          </div>
          <Controller
            name='pageId'
            control={control}
            render={({ field }) => {
              return (
                <TextInput
                  className='h-[50px]'
                  id='pageId'
                  {...field}
                  onBlur={e => saveToStorageOnBlur(e, MessengerType.MESSENGER)}
                  error={errors.pageId?.message}
                />
              );
            }}
          />
        </div>
        <div className='space-y-2'>
          <CopyInput
            subUrl='messenger'
            i18nKey='onboarding.channels.webhook.subheader.messenger'
            subheader={
              <span
                role='button'
                className='cursor-pointer text-primary'
                onClick={() => showTutorialInformation(ROUTES.MESSENGER_TUTORIAL, '4')}
              ></span>
            }
          />
        </div>
      </div>
      <OnboardingQuestion text={t('onboarding.common.difficulties', { connection: 'Messenger' })} />
      <div className='flex items-center'>
        <button className='hidden' ref={buttonRef} />
      </div>
    </form>
  );
};

export { MessengerForm };
