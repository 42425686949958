import React, { useMemo, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import { Button } from 'src/ui/Button/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { businessCardSchema } from 'src/common/utils/schema/business-card.schema';
import { ProfilePreview } from 'src/ui/ProfilePreview/ProfilePreview';
import { cn } from 'src/common/utils/cn';
import { BusinessCardForm } from './BusinessCardForm';
import { FaShareFromSquare, FaSpinner } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { updateUserProfile } from 'src/common/api/user';
import { uploadBrandingLogo } from 'src/common/api/images';
import { useMutation } from '@tanstack/react-query';
import { updateUserLocaly } from 'src/store/user/user.slice';
import { useToast } from 'src/ui/Toast/use-toast';
import { customColorTemplateId } from 'src/common/configs/thematic-templates';
import { PageWrapper } from '../Common/Page.wrapper';
import { PreviewModal } from 'src/ui/Modal/ProfileModal';

type Inputs = z.infer<typeof businessCardSchema>;

const BusinessCardPage = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { user } = useSelector((state: AppStateType) => state.user);
  const dispatch = useDispatch();

  const link = useMemo(() => {
    return `${user?.expert?.domainName}.${
      window.location.hostname.split('.').slice(1).join('.') || 'localhost:3000'
    }`;
  }, [window, user]);

  const [file, setFile] = useState<File | null | 'DELETED'>(null);

  const formButtonRef = useRef<HTMLButtonElement>(null);

  const { mutate: updateUserCard, isLoading } = useMutation({
    mutationFn: async (data: {
      token: string;
      brandLogo: File | 'DELETED' | null;
      vals: Inputs;
    }) => {
      if (data.brandLogo) {
        await uploadBrandingLogo(data.token, user?.id!, data.brandLogo);
      }

      const isCustomColor = data.vals.thematicTemplate.thematicTemplateId === customColorTemplateId;
      const isTemplateLogo = data.vals.brandLogoUrl?.startsWith('http');
      const isDeleted = data.vals.brandLogoUrl === null;

      return updateUserProfile({
        id: user?.id,
        expert: {
          userId: user?.id!,
          ...(isTemplateLogo || isDeleted ? { brandLogoUrl: data.vals.brandLogoUrl } : {}),
          thematicTemplateConfig: {
            thematicTemplateId: isCustomColor
              ? null
              : data.vals.thematicTemplate.thematicTemplateId,
            color: isCustomColor ? data.vals.color : null,
            whiteBackground: data.vals.whiteBackground,
            wasChangedByUser: true,
          },
        },
      });
    },
    onSuccess: data => {
      dispatch(updateUserLocaly(data));
    },
  });

  const form = useForm<Inputs>({
    resolver: zodResolver(businessCardSchema),
    defaultValues: {
      whiteBackground: user?.expert?.thematicTemplateConfig?.whiteBackground,
      brandLogoUrl: user?.expert?.brandLogoUrl,
      thematicTemplate: user?.expert?.thematicTemplateConfig?.thematicTemplate,
      color: user?.expert?.thematicTemplateConfig?.color,
    },
  });

  const resetForm = () => {
    setFile(null);
    form.reset({
      brandLogoUrl: user?.expert?.brandLogoUrl,
      thematicTemplate: user?.expert?.thematicTemplateConfig?.thematicTemplate,
      whiteBackground: user?.expert?.thematicTemplateConfig?.whiteBackground,
      color: user?.expert?.thematicTemplateConfig?.color,
    });
  };

  const { brandLogoUrl, thematicTemplate, whiteBackground, color } = useWatch({
    control: form.control,
  });

  const usedLogoUrl = brandLogoUrl;

  const isChanged = form.formState.isDirty;

  return (
    <PageWrapper
      headerText={t('businessCard.title')}
      isChanged={isChanged}
      activeElemets={
        <>
          <Button
            variant='default'
            className='text-primary px-4 h-[40px]'
            disabled={isChanged}
            onClick={() => {
              navigator.clipboard.writeText(link);
              toast({
                title: 'Link copied to your clipboard',
                variant: 'success',
              });
            }}
          >
            <FaShareFromSquare className='w-6 h-6 md:w-4 md:h-4' />
            <span className='hidden lg:inline'>{t('businessCard.shareButton')}</span>
          </Button>
          <Button
            variant='muted'
            className={cn('bg-secondary hidden md:inline h-[40px] py-0', {
              '!hidden': !isChanged,
            })}
            onClick={resetForm}
          >
            {t('common.discard')}
          </Button>
          <Button
            className='hidden md:flex h-[40px]'
            variant='primary'
            onClick={() => formButtonRef.current?.click()}
            disabled={!isChanged || isLoading}
          >
            {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
            {t('common.save')}
          </Button>
        </>
      }
      mobileActiveElements={
        <div className='flex gap-2 w-full'>
          <Button
            className='w-1/2 h-[40px]'
            variant='primary'
            onClick={() => formButtonRef.current?.click()}
            disabled={!isChanged || isLoading}
          >
            {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
            {t('common.save')}
          </Button>
          <Button
            variant='muted'
            className={cn('w-1/2 bg-secondary h-[40px]', { hidden: !isChanged })}
            onClick={resetForm}
          >
            {t('common.discard')}
          </Button>
        </div>
      }
    >
      <div className='md:mt-0 mt-[15px] flex flex-col md:flex-row w-full'>
        <div className='overflow-hidden rounded-md md:rounded-none lg:w-1/2 bg-white md:border-r border-r-grey-300'>
          <BusinessCardForm
            formButtonRef={formButtonRef}
            {...form}
            file={file}
            setFile={setFile}
            updateUserCard={updateUserCard}
          />
          <div className='px-[20px] md:px-[30px] mb-8 w-full block lg:hidden'>
            <Button variant={'primary_light'} className='w-full' onClick={() => setIsOpen(true)}>
              Preview
            </Button>
          </div>
        </div>
        <div
          className={cn(
            'w-1/2 lg:flex items-center justify-center relative overflow-hidden hidden',
          )}
        >
          <div
            className='absolute w-full h-full blur-[25px] bg-white bg-no-repeat bg-cover'
            style={{ backgroundImage: `url(${usedLogoUrl})` }}
          />
          <div className='py-12'>
            <ProfilePreview
              withCreds
              {...{
                ...user,
                ...user?.expert,
                thematicTemplateConfig: {
                  thematicTemplate,
                  whiteBackground,
                  color,
                },
                brandLogoUrl: usedLogoUrl,
              }}
              disabled
              ignoreHover
            />
          </div>
        </div>
        <PreviewModal
          onClose={() => setIsOpen(false)}
          open={isOpen}
          backgroundImage={user?.expert?.brandLogoUrl}
        >
          <ProfilePreview
            clientPreview
            {...{
              ...user,
              ...user?.expert,
              thematicTemplateConfig: {
                thematicTemplate,
                whiteBackground,
                color,
              },
              brandLogoUrl: usedLogoUrl,
            }}
            withCreds
            disabled
            ignoreHover
          />
        </PreviewModal>
      </div>
    </PageWrapper>
  );
};

export default BusinessCardPage;
