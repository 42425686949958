import { fetchBuilder } from 'src/services/basicFetch';
import { MethodEnum } from '../enums';
import {
  Chat,
  MessengerType,
  MesssageWithChat,
  UploadedFile,
} from 'src/common/interfaces';

export interface ChatActivityStatistic {
  count: number;
  date: string;
}

const getChats = async (): Promise<Chat[]> => {
  return fetchBuilder({}, 'chats/user', MethodEnum.GET);
};

const getFilteredChats = async (queryString: string): Promise<Omit<Chat, 'messages'>[]> => {
  return fetchBuilder({}, `chats/filtered?${queryString}`, MethodEnum.GET);
};

const getChatInfo = async (chatId: string | undefined): Promise<Omit<Chat, 'expert'> | null> => {
  if (!chatId) return null;
  return fetchBuilder({}, `chats/${chatId}/info`, MethodEnum.GET);
};

const sendMessage = async (data: { chatId: string; body: string }) => {
  return fetchBuilder(data, 'messages', MethodEnum.POST);
};

const sendAttachmentsMessage = async (data: {
  chatId: string;
  body: string;
  attachments: UploadedFile[];
}) => {
  return fetchBuilder(data, 'messages/attachment', MethodEnum.POST);
};

const sendPaymentLink = async (chatId: string): Promise<MesssageWithChat> => {
  return fetchBuilder({}, `chats/${chatId}/generate-payment`, MethodEnum.POST);
};

const approveChatRequest = async (chatId: string): Promise<MesssageWithChat> => {
  return fetchBuilder({}, `chats/${chatId}/approve`, MethodEnum.POST);
};

const rejectChatRequest = async (data: {
  chatId: string;
  message: string;
}): Promise<MesssageWithChat> => {
  return fetchBuilder({ message: data.message }, `chats/${data.chatId}/reject`, MethodEnum.POST);
};

const closeChatSession = (chatId: string) => {
  return fetchBuilder({}, `chats/${chatId}/close`, MethodEnum.POST);
};

const getChatsStatistic = () => {
  return fetchBuilder({}, `chats/statistic`, MethodEnum.GET);
};

const getChatsCreationStatistic = (dateRange: string | null): Promise<ChatActivityStatistic[]> => {
  return fetchBuilder({}, `chats/activity-statistic?date=${dateRange}`, MethodEnum.GET);
};

const checkActiveSessions = (messengerType: MessengerType): Promise<number> => {
  return fetchBuilder(
    {},
    `chats/checkActiveSessions?messengerType=${messengerType}`,
    MethodEnum.GET,
  );
};

const closeOnMessengerDelete = (messengerType: MessengerType): Promise<void> => {
  return fetchBuilder(
    {
      messengerType,
    },
    `chats/closeOnMessengerDelete`,
    MethodEnum.POST,
  );
};

export {
  getChats,
  getChatInfo,
  sendMessage,
  sendPaymentLink,
  approveChatRequest,
  rejectChatRequest,
  getFilteredChats,
  closeChatSession,
  sendAttachmentsMessage,
  getChatsStatistic,
  getChatsCreationStatistic,
  checkActiveSessions,
  closeOnMessengerDelete,
};
