import React, { useEffect, useState } from 'react';
import { AuthWrapper } from './AuthWrapper';
import { Form } from 'src/components';
import ReactCodeInput from 'react-code-input';
import { useDispatch, useSelector } from 'react-redux';
import { authUser, verifyOTP } from 'src/store/user/user.actions';
import { AppStateType } from 'src/store';
import { useScreenDetect } from 'src/common/hooks';
import { Button } from 'src/ui/Button/Button';
import { FaArrowRotateRight } from 'react-icons/fa6';
import { Trans, useTranslation } from 'react-i18next';
import { FaQuestion } from 'react-icons/fa';

import './Otp.css';

interface FormContentProps {
  title: string;
}

const FormContent: React.FC<FormContentProps> = ({ title }) => {
  const [pinCode, setPinCode] = useState('');
  const [seconds, setSeconds] = useState(90);
  const timerRef = React.useRef<NodeJS.Timer | null>(null);
  const dispatch = useDispatch();
  const { user, error } = useSelector((state: AppStateType) => state.user);

  const { isTargetScreen: isMobile } = useScreenDetect('mobile');
  const { t } = useTranslation();

  const handleRetryClick = () => {
    setSeconds(90);
    timerRef.current = setInterval(decrementTimer, 1000);
    dispatch(authUser({ email: user?.email }));
  };

  const handleClick = () => {
    if (user) {
      dispatch(
        verifyOTP({
          email: user.email,
          otp: pinCode,
        }),
      );
    }
  };

  const decrementTimer = () => {
    setSeconds(prevSeconds => prevSeconds - 1);
  };

  useEffect(() => {
    timerRef.current = setInterval(decrementTimer, 1000);

    return () => {
      clearInterval(timerRef.current!);
    };
  }, []);

  useEffect(() => {
    if (seconds === 0 && timerRef.current) {
      clearInterval(timerRef.current);
    }
  }, [seconds]);

  const props = {
    inputStyle: {
      fontFamily: 'Open Sans, sans-serif',
      width: isMobile ? 33 : 39,
      height: isMobile ? 45 : 50,
      backgroundColor: '#F3F6F9',
      margin: '0 4px',
      borderRadius: '6px',
      padding: isMobile ? '5px 12px' : '10px 15px',
    },
    inputStyleInvalid: {
      fontFamily: 'Open Sans, sans-serif',
      width: isMobile ? 33 : 39,
      height: isMobile ? 45 : 50,
      backgroundColor: '#FEEDEF',
      margin: '0 4px',
      borderRadius: '6px',
      padding: isMobile ? '5px 12px' : '10px 15px',
    },
  };

  return (
    <Form title={title} className='px-4 sm:px-[30px] py-6 sm:py-[50px]'>
      <div className='flex flex-col gap-[30px] m-0 mt-[30px]'>
        <ReactCodeInput
          className='text-center'
          name='otp'
          inputMode='numeric'
          fields={6}
          onChange={setPinCode}
          value={pinCode}
          isValid={error ? false : true}
          {...props}
        />
        {error ? <div className='text-danger text-center -mt-[16px] -mb-[16px] text-[12px]'>{error}</div> : null}
        <Button variant='primary' disabled={pinCode.length !== 6} onClick={handleClick}>
          {t('auth.continue')}
        </Button>
        <div className='flex flex-col items-center'>
          <div className='text-[14px] leading-[22px]'>{t('auth.sendCodeText')}</div>
          <div className='text-[14px] leading-[22px]'>{t('auth.checkCodeText')}</div>
        </div>
        {seconds ? (
          <div className='text-[14px] leading-[22px] -mt-[22px] text-grey-500 text-center'>
            {t('auth.resendPropose')}
            {`${seconds}s`}
          </div>
        ) : (
          <Button className='text-primary text-[14px] leading-[22px]' onClick={handleRetryClick}>
            <FaArrowRotateRight className='w-[16px] h-[16px]' />
            {t('auth.resend')}
          </Button>
        )}
      </div>
    </Form>
  );
};

export const OtpPage = () => {
  const { t } = useTranslation();

  return (
    <AuthWrapper
      form={<FormContent title={t('auth.otpTitle')} />}
      footer={
        <Trans
          i18nKey={'auth.otpFooter'}
          components={{
            1: <FaQuestion className='text-grey-500' />,
            2: (
              <a href={`mailto:${process.env.REACT_APP_TARGET_EMAIL}`} className='text-primary'></a>
            ),
            3: <div className='flex items-center gap-[8px] text-grey-900' />,
          }}
        />
      }
    />
  );
};


const FormNoAccess: React.FC<FormContentProps> = ({ title }) => {

  const { isTargetScreen: isMobile } = useScreenDetect('mobile');
  const { t } = useTranslation();
  const redirectToHome = () => {
    window.location.href = `${process.env.REACT_APP_ANAMATA_HOME}`;
  };

  return (
    <Form title={title} className='px-4 sm:px-[30px] py-6 sm:py-[50px]'>
      <div className='flex flex-col gap-[30px] m-0 mt-[30px]'>
        <Button variant='primary' onClick={redirectToHome}>
          {t('auth.back')}
        </Button>
      </div>
    </Form>
  );
};

export const WaitlistPage = () => {
  const { t } = useTranslation();
  return (
    <AuthWrapper
      form={<FormNoAccess title={t('auth.waitlist')} />}
      footer={
        <Trans
          i18nKey={'auth.otpFooter'}
          components={{
            1: <FaQuestion className='text-grey-500' />,
            2: (
              <a href={`mailto:${process.env.REACT_APP_TARGET_EMAIL}`} className='text-primary'></a>
            ),
            3: <div className='flex items-center gap-[8px] text-grey-900' />,
          }}
        />
      }
    />
  );
};
