import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSubscriptions } from 'src/common/hooks';
import { cn } from 'src/common/utils';
import { AppStateType } from 'src/store';
import { useAiAssistantStore } from 'src/store/onboarding/ai-assistant.store';
import { Button } from 'src/ui/Button/Button';
import { CheckBox } from 'src/ui/CheckBox/CheckBox';
import { DropDown } from 'src/ui/DropDownRadix/DropDown';
import { Textarea } from 'src/ui/TextInput/TextInput';
import { TypedTextInput } from 'src/ui/TypedTextInput/TypedTextInput';

interface AiSubscriptionsProps {}

export const AiSubscriptions: React.FC<AiSubscriptionsProps> = () => {
  const {
    subscriptions,
    setIsCardActive,
    onDescriptionChange,
    onPriceChange,
    onQuestionsAmountChange,
  } = useSubscriptions();
  const { updateSubscriptionsViaAI } = useAiAssistantStore();
  const { expertSubscriptions } = useSelector((state: AppStateType) => state.user);
  const { t } = useTranslation();

  const { mutate: update } = updateSubscriptionsViaAI;

  return (
    <div className='flex justify-center mt-[8px] w-full'>
      <div className='md:min-w-[400px] md:w-10/12 w-full'>
        {subscriptions.map(({ isEnabled, type, options, description, price, amount }, idx) => (
          <div key={idx} className='w-full'>
            <div
              className='flex gap-[10px] items-center mb-[16px]'
              onClick={() => setIsCardActive(idx, !isEnabled)}
            >
              <CheckBox checked={isEnabled} />
              <span
                className={cn('leading-[22px] text-[14px] text-primary', {
                  'text-grey-500': !isEnabled,
                })}
              >
                <label>{t(`onboarding.subscriptions.options.${type}`)}</label>
              </span>
            </div>
            {isEnabled ? (
              <div className='flex flex-col gap-[16px] mb-[16px]'>
                <Textarea
                  value={description}
                  onChange={e => onDescriptionChange(idx, e.target.value)}
                  disabled={!isEnabled}
                  placeholder={t('onboarding.subscriptions.options.placeholder')}
                  rows={4}
                />
                <div className='flex flex-col md:flex-row items-center gap-[16px]'>
                  <div className='w-full md:w-[calc(50%-8px)]'>
                    <TypedTextInput
                      id={type}
                      value={price || ''}
                      onChange={e => onPriceChange(idx, +e.target.value)}
                      disabled={!isEnabled}
                      measure='USD'
                      placeholder={t('onboarding.subscriptions.options.price')}
                    />
                  </div>
                  {options && options.length ? (
                    <div className='w-full md:w-[calc(50%-8px)]'>
                      <DropDown
                        name='ai_sub_amount'
                        onValueChange={v => onQuestionsAmountChange(idx, +v)}
                        options={options}
                        disabled={!isEnabled}
                        value={amount.toString()}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        ))}
        <div className='flex justify-center'>
          <Button variant='primary' onClick={() => update(expertSubscriptions)}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};
