import React, { RefObject, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Label } from 'src/ui/Label/Label';
import { TextInput, Textarea } from 'src/ui/TextInput/TextInput';
import { useTranslation } from 'react-i18next';
import { InstagramSettingsInputs } from 'src/common/utils/schema/channels-settings.schema';
import { Button } from 'src/ui/Button/Button';
import { FaCircleInfo } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/store/user/user.slice';
import { ROUTES } from 'src/router/routes';
import { useTutorialNavigation } from 'src/common/hooks/useTutorialNavigations';
import { OnboardingQuestion } from 'src/pages/Onboarding/Subpages/Common';
import { CopyInput } from 'src/ui/CopyInput/CopyInput';
import { getObjectFromStorage, saveToStorageOnBlur } from 'src/common/utils';
import { MessengerType } from 'src/common/interfaces';

interface InstagramFormProps extends UseFormReturn<InstagramSettingsInputs, any, undefined> {
  buttonRef: RefObject<HTMLButtonElement>;
  onSubmit: (vals: InstagramSettingsInputs) => void;
}

const InstagramForm = ({
  reset,
  buttonRef,
  control,
  handleSubmit,
  formState: { errors },
  onSubmit,
}: InstagramFormProps) => {
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  const { showTutorialInformation } = useTutorialNavigation();

  useEffect(() => {
    const data = getObjectFromStorage<InstagramSettingsInputs>(MessengerType.INSTAGRAM);
    reset({
      pageId: user?.expert?.settings?.instagramSettings?.pageId || data?.pageId || undefined,
      pageAccessToken:
        user?.expert?.settings?.instagramSettings?.pageAccessToken ||
        data?.pageAccessToken ||
        undefined,
      username: user?.expert?.settings?.instagramSettings?.username || data?.username || undefined,
    });
  }, [user]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='space-y-6 sticky top-0'>
      <div className='flex flex-col gap-[30px]'>
        <div className='space-y-2'>
          <div className='flex items-center justify-between'>
            <Label htmlFor='pageAccessToken'>
              {t('onboarding.channels.instagram.pageAccessToken')}
            </Label>
            <Button
              className='text-primary'
              type='button'
              onClick={() => showTutorialInformation(ROUTES.INSTAGRAM_TUTORIAL, '5')}
            >
              <FaCircleInfo className='h-4 w-4' />
              <div className='flex-grow text-left'>
                {t('onboarding.channels.instagram.tip.pageAccessToken')}
              </div>
            </Button>
          </div>
          <Controller
            name='pageAccessToken'
            control={control}
            render={({ field }) => {
              return (
                <Textarea
                  id='pageAccessToken'
                  {...field}
                  rows={4}
                  error={errors.pageAccessToken?.message}
                  onBlur={e => saveToStorageOnBlur(e, MessengerType.INSTAGRAM)}
                />
              );
            }}
          />
        </div>
        <div className='space-y-2'>
          <div className='flex items-center justify-between'>
            <Label htmlFor='msgAccountSid'>{t('onboarding.channels.instagram.pageId')}</Label>
            <Button
              className='text-primary'
              type='button'
              onClick={() => showTutorialInformation(ROUTES.INSTAGRAM_TUTORIAL, '5')}
            >
              <FaCircleInfo className='h-4 w-4' />
              <div className='flex-grow text-left'>
                {t('onboarding.channels.instagram.tip.pageId')}
              </div>
            </Button>
          </div>
          <Controller
            name='pageId'
            control={control}
            render={({ field }) => {
              return (
                <TextInput
                  className='h-[50px]'
                  id='pageId'
                  {...field}
                  error={errors.pageId?.message}
                  onBlur={e => saveToStorageOnBlur(e, MessengerType.INSTAGRAM)}
                />
              );
            }}
          />
        </div>
        <div className='space-y-2'>
          <Label htmlFor='username'>{t('onboarding.channels.instagram.username')}</Label>
          <Controller
            name='username'
            control={control}
            render={({ field }) => {
              return (
                <TextInput
                  className='h-[50px]'
                  id='username'
                  {...field}
                  error={errors.username?.message}
                  onBlur={e => saveToStorageOnBlur(e, MessengerType.INSTAGRAM)}
                />
              );
            }}
          />
        </div>
        <div className='space-y-2'>
          <CopyInput
            subUrl='instagram'
            i18nKey='onboarding.channels.webhook.subheader.instagram'
            subheader={
              <span
                role='button'
                className='cursor-pointer text-primary'
                onClick={() => showTutorialInformation(ROUTES.INSTAGRAM_TUTORIAL, '6')}
              ></span>
            }
          />
        </div>
      </div>
      <OnboardingQuestion text={t('onboarding.common.difficulties', { connection: 'Instagram' })} />
      <div className='flex items-center'>
        <button className='hidden' ref={buttonRef} />
      </div>
    </form>
  );
};

export { InstagramForm };
