import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSubscriptions } from 'src/common/hooks';
import { cn } from 'src/common/utils';
import { Card } from 'src/ui/Card/Card';

export interface TransactionStatsProps {
  title: string;
  amount: number | string;
  className?: string;
  currency?: string;
}

export const TransactionStat: React.FC<TransactionStatsProps> = ({
  amount,
  title,
  className,
  currency,
}) => {
  return (
    <Card variant='dashed' className={cn('w-full border-[#D8D8E5]', className)}>
      <div className='text-grey-500 uppercase text-[11px] tracking-widest'>{title}</div>
      <div className='text-[16px]'>
        {currency}
        {amount}
      </div>
    </Card>
  );
};

export interface TransactionsStatsProps {
  amount: number;
  payments: number;
  consultations: number;
  pack: number;
  single: number;
  className?: string;
}

export const TransactionStats: React.FC<TransactionsStatsProps> = ({
  amount,
  payments,
  consultations,
  pack,
  single,
  className,
}) => {
  const { subscriptions } = useSubscriptions();
  const { t } = useTranslation();

  const enabledOptions = useMemo(
    () => ({
      single: subscriptions.find(s => s.type === 'single')?.isEnabled,
      consultation: subscriptions.find(s => s.type === 'consultation')?.isEnabled,
      pack: subscriptions.find(s => s.type === 'pack')?.isEnabled,
    }),
    [subscriptions],
  );

  return (
    <div className={cn('grid md:flex gap-[15px]', className)}>
      <TransactionStat
        title='Amount'
        amount={(+amount).toFixed(2)}
        currency='$'
        className='col-span-2'
      />
      <TransactionStat title='Payments' amount={payments} />
      {enabledOptions.consultation || consultations ? (
        <TransactionStat
          title={t('onboarding.subscriptions.options.consultation')}
          amount={consultations}
        />
      ) : null}
      {enabledOptions.pack || pack ? (
        <TransactionStat title={t('onboarding.subscriptions.options.pack')} amount={pack} />
      ) : null}
      {enabledOptions.single || single ? (
        <TransactionStat title={t('onboarding.subscriptions.options.single')} amount={single} />
      ) : null}
    </div>
  );
};
