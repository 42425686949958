import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getUserViaToken } from 'src/store/user/user.actions';
import { AppStateType } from 'src/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'src/router/routes';
import { LoginPage, OtpPage, WaitlistPage } from './components';

export const AuthPage = () => {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const reject_code_param = searchParams.get("reject_code");
    if (reject_code_param === 'WAITLIST_MODE') return <WaitlistPage />

    const accessToken = searchParams.get("access-token");
    

    const { success, userToken, user, reject_code } = useSelector(
        (state: AppStateType) => state.user
    )
    const dispatch = useDispatch();

    useEffect(() => {
        if (accessToken && !userToken) {
            dispatch(getUserViaToken({
                token: accessToken
            }))
        }
    }, [accessToken]);


    useEffect(() => {
        if (userToken && success) {
            navigate(`/${ROUTES.CHATS}`);
        }
    }, [userToken, success]);

    if (user?.email && reject_code === 'WAITLIST_MODE') return <WaitlistPage />
    return (
        user?.email && !userToken ? <OtpPage /> : <LoginPage/>
    )
}
