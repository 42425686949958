import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/ui/Button/Button';

interface TutorialPageWrapperProps {
  children?: React.ReactNode;
  backFunction: () => void;
}

export const TutorialPageWrapper: React.FC<TutorialPageWrapperProps> = ({
  children,
  backFunction,
}) => {
  const { t } = useTranslation();

  return (
    <main className='px-[15px] bg-white xl:bg-light md:px-[30px]'>
      {children}
      <div className='w-full px-[45px] py-[30px] sticky bottom-0 bg-white'>
        <Button variant='primary' className='w-full h-[50px] text-[14px] font-[600]' onClick={backFunction}>
          {t('onboarding.tutorials.backBtn')}
        </Button>
      </div>
    </main>
  );
};
