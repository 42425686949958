import React from 'react';
import { Switch } from '../Switch/Switch';
import { Textarea } from '../TextInput/TextInput';
import { TypedTextInput } from '../TypedTextInput/TypedTextInput';
import { DropDown } from '../DropDownRadix/DropDown';
import { useTranslation } from 'react-i18next';
import { ExpertSubscription } from 'src/store/user/user.slice';
import { cn } from 'src/common/utils';
export interface SubscriptionCardProps {
  headerText: string;
  subscription: ExpertSubscription;
  setIsCardActive: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDescriptionChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onPriceChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onQuestionsAmountChange: (value: string) => void;
  width?: string;
  options?: {
    text: string;
    value: string;
  }[];
  className?: string;
}

export const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  headerText,
  width = 'w-full',
  subscription,
  setIsCardActive,
  onDescriptionChange,
  onPriceChange,
  onQuestionsAmountChange,
  options,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        `${width}
        flex flex-col 
        px-[16px] py-[16px]
        gap-[16px]
        border-solid rounded-[6px] border-secondary border-[1px]
      `,
        className,
      )}
    >
      <div className='flex justify-start items-center'>
        <Switch isChecked={subscription.isEnabled} onChange={setIsCardActive} />
        <div className='ml-[16px] font-[700] leading-[23px]'>{headerText}</div>
      </div>
      <div className='flex flex-col gap-[16px]'>
        <Textarea
          className='text-[14px] leading-[22px]'
          value={subscription.description}
          onChange={onDescriptionChange}
          disabled={!subscription.isEnabled}
          placeholder={t('onboarding.subscriptions.options.placeholder')}
          limit={450}
          rows={4}
        />
        <div className='flex flex-col md:flex-row items-start gap-[16px] -mt-[6px]'>
          <div className='w-full md:w-[calc(50%-8px)]'>
            <TypedTextInput
              id={subscription.type}
              value={subscription.price || ''}
              onChange={onPriceChange}
              disabled={!subscription.isEnabled}
              measure='USD'
              placeholder={t('onboarding.subscriptions.options.price')}
            />
          </div>
          {options && options.length ? (
            <div className='w-full md:w-[calc(50%-8px)]'>
              <DropDown
                className='h-[50px]'
                name='sub_amount'
                onValueChange={onQuestionsAmountChange}
                options={options}
                disabled={!subscription.isEnabled}
                value={subscription.amount.toString()}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
