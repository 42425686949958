import React, { useMemo } from 'react';
import { MdOutlineMoreVert } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { Avatar, AvatarFallback, AvatarImage } from '../Avatar/Avatar';
import { getUserInitials } from 'src/common/utils/getUserInitials';
import { MobileBackButton } from '../MobileBackButton/MobileBackButton';
import { FaRobot } from 'react-icons/fa';
import { cn } from 'src/common/utils';
import { Button, ButtonProps } from '../Button/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../DropdownMenu/DropdownMenu';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { closeChatSession } from 'src/common/api/chat';
import { useActiveChat } from 'src/store/chat/useActiveChat';
import { FaLock, FaSpinner } from 'react-icons/fa6';
import { ChatStatus, MessengerType } from 'src/common/interfaces';
import { useChatStore } from 'src/store/chat/chatStore';
import { useSearchParams } from 'src/common/hooks';
import { FaCreditCard } from 'react-icons/fa6';
import { SendPaymentButton } from 'src/pages/Chat/PaymentButton';
import { extendedMessengerName } from 'src/common/utils/extendMessengerName';
import { ROUTES } from 'src/router/routes';
import { useMobileDetect } from 'src/common/hooks/useMobileDetect';

interface ChatHeaderProps {
  username: string;
  userImage: string;
  messengerType: MessengerType;
  withAiHelper?: boolean;
}

const ChatHeader = ({ username, userImage, messengerType, withAiHelper }: ChatHeaderProps) => {
  const { chatId } = useParams();
  const { searchParams } = useSearchParams();
  const { isMobile } = useMobileDetect();

  const backParam = useMemo(() => {
    const status = searchParams.get('chatStatus');
    if (status && isMobile) {
      return `?chatStatus=${status}`;
    }
    return '';
  }, [isMobile]);

  return (
    <div className='flex items-center justify-between px-[15px] py-[12px] sm:px-[30px] sm:py-[17px] bg-white fixed top-0 inset-x-0 sm:static z-20'>
      <div className='flex items-center'>
        <MobileBackButton to={`/${ROUTES.CHATS}${backParam}`} className='mr-[15px]' />
        {withAiHelper && (
          <div className='bg-info w-[36px] h-[36px] rounded-full flex items-center justify-center z-10 outline outline-white'>
            <FaRobot className='text-white w-[20px] h-[20px] ' />
          </div>
        )}
        <Avatar className={cn('w-[36px] h-[36px]', { '-translate-x-2': withAiHelper })}>
          <AvatarImage src={userImage} alt={username} />
          <AvatarFallback>{getUserInitials(username)}</AvatarFallback>
        </Avatar>
        <div className='ml-2 flex flex-col max-w-xs '>
          <p className='font-semibold truncate text-[16px]'>{username}</p>
          <p className='text-grey text-[12px]'>{extendedMessengerName[messengerType]}</p>
        </div>
      </div>
      {chatId && <MoreDropdown chatId={chatId} />}
    </div>
  );
};

const MoreDropdown = ({ chatId }: { chatId: string }) => {
  const { t } = useTranslation();

  const { activeChatInfo } = useChatStore();

  const { mutate: closeConversation, isLoading: isCompleting } = useMutation({
    mutationFn: closeChatSession,
  });

  const isClosed =
    activeChatInfo?.status === ChatStatus.CLOSED ||
    activeChatInfo?.status === ChatStatus.CLOSING ||
    activeChatInfo?.status === ChatStatus.CLOSING_DETAILS_PROVIDE ||
    activeChatInfo?.status === ChatStatus.CLOSING_NOTIFIED;
  const isPaymentSent = activeChatInfo?.status === ChatStatus.PAYMENT_SENT;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className='flex items-end flex-col gap-1'>
        <MdOutlineMoreVert className='w-8 h-8 text-grey-500 font-bold' />
      </DropdownMenuTrigger>
      <DropdownMenuContent side='bottom' className='max-w-[300px] p-0' align='end'>
        <DropdownMenuGroup className='p-2'>
          {isPaymentSent && <SendPaymentButton ButtonComp={ResendLinkButton} />}
          <DropdownMenuItem
            className='font-medium'
            onClick={e => {
              e.preventDefault();
              closeConversation(chatId);
            }}
            disabled={isCompleting || isClosed}
          >
            {isCompleting ? (
              <FaSpinner className='animate-spin w-4 h-4 text-black mr-4' />
            ) : (
              <FaLock className='w-4 h-4 text-black mr-4' />
            )}
            {t('chats.header.complete')}
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

interface ResendLinkButtonProps extends ButtonProps {
  isLoading?: boolean;
}

const ResendLinkButton = ({ isLoading, disabled, ...props }: ResendLinkButtonProps) => {
  const { t } = useTranslation();

  return (
    <DropdownMenuItem
      className='font-medium'
      disabled={disabled || isLoading}
      asChild
      onClick={e => {
        e.preventDefault();
      }}
    >
      <Button {...props}>
        {isLoading ? (
          <FaSpinner className='animate-spin w-4 h-4 text-black mr-2  ' />
        ) : (
          <FaCreditCard className='w-4 h-4 text-black mr-2' />
        )}
        {t('chats.header.regeneratePayment')}
      </Button>
    </DropdownMenuItem>
  );
};

export default ChatHeader;
