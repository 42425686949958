import React, { ReactNode } from 'react';
import { Card, type CardProps } from '../Card/Card';
import { useTranslation, Trans } from 'react-i18next';

import { cn } from 'src/common/utils';
import { DatePlaceholder } from 'src/components/DatePlaceholder';
import { FaLock } from 'react-icons/fa';
import Markdown from 'src/components/Markdown/Markdown';
import { whatsappMarkdownSettings } from 'src/common/configs/whatsapp-markdown';

const systemMessageType = {
  CLIENT_APPROVED: 'client-approved',
  PAYMENT_LINK: 'payment-link',
  PAYMENT_SUCCESS: 'payment-success',
  PAYMENT_REJECTED: 'payment-rejected',
  CONVERSATION_COMPLETED: 'conversation-completed',
  AVAILABLE_CONSULTATIONS_AMOUT_INFO: 'available-consultations-amount-info',
  CONVERSATION_INITIATE_END: 'conversation-initiate-end',
} as const;

type DefaultProps = {
  date: string | Date | number;
} & CardProps;

type ClientApprovedProps = {
  paymentLink: string;
  offerLink?: string | null;
} & DefaultProps;

type PaymentLinkProps = {
  paymentLink: string;
} & DefaultProps;

type ConversationCompletedProps = {
  isAiHelper?: boolean;
} & DefaultProps;

type ConsultationsAmountInfoProps = {
  body: string;
} & DefaultProps;

type SystemMessageProps =
  | ({ type: typeof systemMessageType['CLIENT_APPROVED'] } & ClientApprovedProps)
  | ({ type: typeof systemMessageType['PAYMENT_LINK'] } & PaymentLinkProps)
  | ({ type: typeof systemMessageType['CONVERSATION_COMPLETED'] } & ConversationCompletedProps)
  | ({ type: typeof systemMessageType['CONVERSATION_INITIATE_END'] } & ConsultationsAmountInfoProps)
  | ({
      type: typeof systemMessageType['AVAILABLE_CONSULTATIONS_AMOUT_INFO'];
    } & ConsultationsAmountInfoProps)
  | ({
      type: Exclude<
        typeof systemMessageType[keyof typeof systemMessageType],
        | 'client-approved'
        | 'payment-link'
        | 'conversation-completed'
        | 'available-consultations-amount-info'
        | 'conversation-initiate-end'
      >;
    } & DefaultProps);

const SystemMessage = (props: SystemMessageProps) => {
  switch (props.type) {
    case systemMessageType['CLIENT_APPROVED']:
      return <ClientApproved {...props} />;
    case systemMessageType['PAYMENT_LINK']:
      return <PaymentLinkInfo {...props} />;
    case systemMessageType['PAYMENT_REJECTED']:
      return <PaymentRejected {...props} />;
    case systemMessageType['PAYMENT_SUCCESS']:
      return <PaymentSuccess {...props} />;
    case systemMessageType['CONVERSATION_COMPLETED']:
      return <ConversationCompleted {...props} />;
    case systemMessageType['AVAILABLE_CONSULTATIONS_AMOUT_INFO']:
      return <ConsultationsAmountInfo {...props} />;
    case systemMessageType['CONVERSATION_INITIATE_END']:
      return <ConversationInitiateEnd {...props} />;
    default:
      return <></>;
  }
};

const ClientApproved = ({
  offerLink,
  paymentLink,
  date,
  className,
  ...props
}: ClientApprovedProps) => {
  return (
    <Card
      variant={'dashed'}
      className={cn('border-primary bg-primary-light px-[15px] pt-[10px] pb-[5px]', className)}
      {...props}
    >
      <div className='flex flex-col gap-2'>
        <Trans
          i18nKey={'systemMessage.clientApproved'}
          components={{
            1: <span />,
            2: <a href={paymentLink} target='_blank' rel='noreferrer' className='text-primary' />,
            3: offerLink ? (
              <a href={offerLink} target='_blank' rel='noreferrer' className='text-primary' />
            ) : (
              <span className='hidden' />
            ),
          }}
        />
      </div>
      <span className='flex w-full justify-end text-muted'>
        <DatePlaceholder date={date} />
      </span>
    </Card>
  );
};

const PaymentLinkInfo = ({ paymentLink, date, className, ...props }: PaymentLinkProps) => {
  const { t } = useTranslation();

  return (
    <Card
      variant={'dashed'}
      className={cn('border-primary bg-primary-light px-[15px] pt-[10px] pb-[5px]', className)}
      {...props}
    >
      <a href={paymentLink} target='_blank' rel='noreferrer' className='text-primary'>
        💳 {t('systemMessage.link')}
      </a>
      <span className='flex w-full justify-end text-muted'>
        <DatePlaceholder date={date} />
      </span>
    </Card>
  );
};

const PaymentSuccess = ({ date, className, ...props }: DefaultProps) => {
  const { t } = useTranslation();

  return (
    <Card
      variant={'dashed'}
      className={cn('border-success bg-success-light px-[15px] pt-[10px] pb-[5px]', className)}
      {...props}
    >
      <span>✅ {t('systemMessage.success')}</span>
      <span className='flex w-full justify-end text-muted'>
        <DatePlaceholder date={date} />
      </span>
    </Card>
  );
};

const PaymentRejected = ({ date, className, ...props }: DefaultProps) => {
  const { t } = useTranslation();

  return (
    <Card
      variant={'dashed'}
      className={cn('bg-danger-light border-danger px-[15px] pt-[10px] pb-[5px]', className)}
      {...props}
    >
      <span>❌ {t('systemMessage.reject')}</span>
      <span className='flex w-full justify-end text-muted'>
        <DatePlaceholder date={date} />
      </span>
    </Card>
  );
};

const ConversationInitiateEnd = ({
  date,
  className,
  body,
  ...props
}: ConsultationsAmountInfoProps) => {
  return (
    <Card
      variant={'dashed'}
      className={cn('bg-primary-light  border-primary px-[15px] pt-[10px] pb-[5px]', className)}
      {...props}
    >
      {body}
      <span className='flex w-full justify-end text-muted'>
        <DatePlaceholder date={date} />
      </span>
    </Card>
  );
};

const ConversationCompleted = ({
  date,
  className,
  isAiHelper,
  ...props
}: ConversationCompletedProps) => {
  const { t } = useTranslation();

  return (
    <div className='w-full py-4 px-12'>
      <div className='w-full items-center flex flex-col gap-2'>
        <FaLock className={cn('w-6 h-6 text-grey-500', { 'text-info opacity-75': isAiHelper })} />
        <span className='font-bold uppercase tracking-wide text-grey-900'>
          {t('systemMessage.conversationCompleted')}
        </span>
        <DatePlaceholder
          date={date}
          className='justify-center uppercase tracking-wide text-sm text-grey-900'
          variant='date-no-today'
        />
        <hr className={cn('w-full border-black mt-4 opacity-20', { 'text-info': isAiHelper })} />
      </div>
    </div>
  );
};

const ConsultationsAmountInfo = ({
  body,
  date,
  className,
  ...props
}: ConsultationsAmountInfoProps) => {
  return (
    <Card
      variant={'dashed'}
      className={cn('border-primary bg-primary-light px-[15px] pt-[10px] pb-[5px]', className)}
      {...props}
    >
      <Markdown className='whitespace-pre-line' components={whatsappMarkdownSettings}>
        {body}
      </Markdown>
      <span className='flex w-full justify-end text-muted'>
        <DatePlaceholder date={date} />
      </span>
    </Card>
  );
};

export type { SystemMessageProps };
export default SystemMessage;
