import React, {
  type Dispatch,
  useCallback,
  useImperativeHandle,
  useRef,
} from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import { cn } from "src/common/utils/cn";
import { Button } from "../Button/Button";
import { Textarea } from "../TextInput/TextInput";
import { AttachmentsDialog } from "./AttachmentsDialog";

interface MessageInputProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange"> {
  value: string;
  onChange?: Dispatch<React.SetStateAction<string>>;
  setIsAttachmentLoading?: Dispatch<React.SetStateAction<boolean>>;
  onMessageSend?: (message: string) => void;
  isChatbot?: boolean;
  placeholder?: string;
  isDemonstration?: boolean;
  connectButton?: React.ReactNode;
  disableSending?: boolean;
}

const MessageInput = React.forwardRef<HTMLTextAreaElement, MessageInputProps>(
  (
    {
      value,
      onChange,
      onMessageSend,
      setIsAttachmentLoading,
      isChatbot,
      placeholder,
      isDemonstration,
      connectButton,
      disableSending,
      ...props
    },
    ref
  ) => {
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
    useImperativeHandle(ref, () => textAreaRef.current!);
    const handleKeyPress = (
      event: React.KeyboardEvent<HTMLTextAreaElement>
    ) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (event.ctrlKey) {
          onMessageSend?.(value);
        } else {
          onChange?.(value + "\n");
        }
      }
    };

    return (
      <div>
        <div className="w-full flex items-center">
          <div className="w-full">
            <Textarea
              minHeight={30}
              maxHeight={500}
              autoFocus={!window.isMobile}
              placeholder={placeholder || "Your message to client..."}
              value={value}
              rows={1}
              onChange={(e) => onChange?.(e.target.value)}
              onKeyDown={handleKeyPress}
              disabled={props.disabled}
              className={cn(
                "mr-2 w-full focus:outline-none focus:bg-transparent bg-inherit border-none focus:outline-0 focus:shadow-none ring-0 py-2 pl-0 focus-within:bg-transparent focus-within:shadow-none focus:outline-none hover:shadow-none group-hover:shadow-none",
                {
                  "bg-transparent": props.disabled,
                }
              )}
              {...props}
              ref={textAreaRef}
            />
          </div>

          <div className={cn("flex items-center gap-[25px]")}>
            {!isChatbot && (
              <>
                {connectButton}
                {!value.length && (
                  <AttachmentsDialog
                    setIsAttachmentLoading={setIsAttachmentLoading}
                    disabled={props.disabled}
                  />
                )}
              </>
            )}

            <div
              onClick={(e) => {
                textAreaRef.current?.focus();
              }}
            >
              <Button
                onClick={() => {
                  if (!!value.trim()) {
                    onMessageSend?.(value);
                  }
                }}
              >
                <FaArrowCircleUp
                  type="submit"
                  className={cn(
                    "text-3xl text-blue cursor-pointer w-[22px] h-[22px]",
                    {
                      "text-grey ": !value.trim() || disableSending,
                    }
                  )}
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

MessageInput.displayName = "ChatMessageInput";

export default MessageInput;
