import { ExpertSubscriptionTypeEnum } from '../enums/expert-plan-type.enum';
import { AiChat } from './ai-chat';
import { UploadedFile } from './tutorial.types';

enum MessageStatus {
  SENT = 'sent',
  DELIVERED = 'delivered',
  READ = 'read',
  FAILED = 'failed',
}

enum MessageType {
  SENT = 'sent',
  RECEIVED = 'received',
}

enum MessengerType {
  WHATSAPP = 'whatsapp',
  TELEGRAM = 'telegram',
  MESSENGER = 'messenger',
  INSTAGRAM = 'instagram',
}

enum MessageVariant {
  DEFAULT = 'default',
  CLIENT_DESCRIPTION = 'client-description',
  CLIENT_APPROVED = 'client-approved',
  PAYMENT_LINK = 'payment-link',
  PAYMENT_SUCCESS = 'payment-success',
  PAYMENT_REJECTED = 'payment-rejected',
  CONVERSATION_COMPLETED = 'conversation-completed',
  REQUEST_REJECTED = 'request-rejected',
  AVAILABLE_CONSULTATIONS_AMOUT_INFO = 'available-consultations-amount-info',
  CONVERSATION_INITIATE_END = 'conversation-initiate-end',
}

type MessageData =
  | {
      variant: MessageVariant.DEFAULT;
      body: string;
    }
  | { variant: MessageVariant.PAYMENT_LINK; paymentLink: string };

type Client = {
  id: string;
  name: string;
  avatar: string;
  messengerType: MessengerType;
  phoneNumber: string;
};

type ChatMessage = {
  id: string;
  body: string;
  to: string;
  from: string;
  sid: string;
  variant: MessageVariant;
  paymentLink: string;
  offerLink: string;
  messengerType: string;
  status: MessageStatus;
  type: MessageType;
  attachments?: UploadedFile[];
  availableConversations: number;
  usedConversations: number;
  createdAt: string;
  updatedAt: string;
};

enum ChatStatus {
  CREATED = 'created',
  REQUESTED = 'requested',
  IDLE = 'idle',
  PAYMENT_SENT = 'payment-sent',
  ACTIVE = 'active',
  CLOSED = 'closed',
  CLOSING = 'closing',
  CLOSING_NOTIFIED = 'closing-notified',
  CLOSING_DETAILS_PROVIDE = 'closing-details-provide',
}

type MesssageWithChat = ChatMessage & { chat: Chat };

type Expert = {
  id: string;
  brandName: string | null;
  brandLogoUrl: string | null;
  description: string | null;
  brandScheme: string | null;
};

type Chat = {
  id: string;
  client: Client;
  expert: Expert;
  status: ChatStatus;
  messages: ChatMessage[];
  unreadCount: number;
  aiChatId: number | null;
  aiChat: AiChat;
  subscriptionType: ExpertSubscriptionTypeEnum | null;
  messengerType: MessengerType;
  createdAt: string;
  updatedAt: string;
};

enum ChatVariant {
  ACTIVE = 'active',
  REQUESTED = 'requested',
  COMPLETED = 'completed',
}

export { ChatStatus, MessageVariant, MessageStatus, MessageType, ChatVariant, MessengerType };
export type { Chat, MessageData, ChatMessage, Client, Expert, MesssageWithChat };
