import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { cn, getUserInitials, subscriptionTypeToString } from 'src/common/utils';
import { useTranslation } from 'react-i18next';
import { Avatar, AvatarFallback, AvatarImage } from '../Avatar/Avatar';
import { Card } from '../Card/Card';
import dayjs from 'dayjs';

import { DatePlaceholder } from 'src/components/DatePlaceholder';
import { ActionButtons } from './ActionButtons';
import { ExpertSubscriptionTypeEnum } from 'src/common/enums/expert-plan-type.enum';
import { MessageVariant, type ChatMessage, MessengerType } from 'src/common/interfaces';
import { whatsappMarkdownSettings } from 'src/common/configs/whatsapp-markdown';
import Markdown from 'src/components/Markdown/Markdown';
import { MessengerIcon } from '../MessengerIcon/MessengerIcon';

export interface ChatClientRequestProps {
  username: string;
  userImage: string;
  messengerType: MessengerType;
  subscriptionType: ExpertSubscriptionTypeEnum | null;
  message?: Pick<ChatMessage, 'body' | 'createdAt' | 'variant'> | null;
  chatId: string;
  isDemo?: boolean;
  isRequested?: boolean;
}

const ChatClientRequest = ({
  username,
  userImage,
  subscriptionType,
  messengerType,
  message,
  chatId,
  isDemo,
  isRequested,
}: ChatClientRequestProps) => {
  const { t } = useTranslation();

  const messageBody =
    message?.body && message?.variant === MessageVariant.CLIENT_DESCRIPTION
      ? message.body
      : 'New request';

  return (
    <div className='flex w-full justify-center flex-col items-center px-4 pb-4 pt-0'>
      <Card
        className={cn('w-full max-w-[500px] flex justify-center items-center', {
          'bg-transparent mb-0 pb-0': isDemo,
        })}
      >
        <div className='w-full flex flex-col justify-around items-center'>
          <div className='pb-4 flex flex-col items-center justify-center'>
            <div className='relative rounded-full mt-2 w-28 h-28 object-cover'>
              <Avatar className='w-28 h-28'>
                <AvatarImage src={userImage} alt={username} className='object-cover' />
                <AvatarFallback>{getUserInitials(username)}</AvatarFallback>
              </Avatar>
              {
                <div
                  className={cn(
                    'absolute bottom-0 right-0 w-7 h-7 rounded-full flex items-center justify-center z-10 border-white border-2',
                    {
                      'bg-whatsapp': messengerType === MessengerType.WHATSAPP,
                      'bg-instagram': messengerType === MessengerType.INSTAGRAM,
                      'bg-messenger': messengerType === MessengerType.MESSENGER,
                      'bg-telegram': messengerType === MessengerType.TELEGRAM,
                    },
                  )}
                >
                  <MessengerIcon
                    messengerType={messengerType}
                    className='w-5 h-5 text-white ml-[0.5px]'
                  />
                </div>
              }
            </div>
            <h3 className='font-bold mt-2 text-grey-900 text-lg'>{username}</h3>
          </div>
          {!isDemo ? (
            <>
              {subscriptionType && (
                <div className='pb-4 flex flex-col items-center justify-center text-grey-900 '>
                  <h3 className='text-lg text-center'>{t('chats.option')}</h3>
                  <span className='font-bold'>{`"${subscriptionTypeToString[subscriptionType]}"`}</span>
                </div>
              )}

              <div
                className={cn('rounded-md bg-chat-bg w-full px-[16px] pt-[10px] pb-[13px]', {
                  'mb-0': isDemo,
                })}
              >
                <div className='flex flex-col items-center justify-center w-full '>
                  <div className='text-grey-900 break-words truncate-three w-full'>
                    {messageBody && (
                      <Markdown components={whatsappMarkdownSettings} className='text-left w-full'>
                        {messageBody as string}
                      </Markdown>
                    )}
                  </div>
                </div>

                <div className='w-full p-1 flex flex-col items-end justify-end'>
                  {message?.createdAt && (
                    <DatePlaceholder
                      date={message?.createdAt}
                      className='text-xs'
                      template='h:mm A'
                    />
                  )}
                </div>
              </div>
            </>
          ) : null}
          {!isDemo && isRequested && chatId && <ActionButtons chatId={chatId} />}
          {!isDemo && isRequested && (
            <span className='mt-4 text-grey-400'>{t('chats.clientRequest.description')}</span>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ChatClientRequest;
