import React, { useEffect, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Label } from 'src/ui/Label/Label';
import { TextInput, Textarea } from 'src/ui/TextInput/TextInput';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'src/common/hooks';
import {
  WhatsappSettingsInputs,
  whatsappSettingsSchema,
} from 'src/common/utils/schema/channels-settings.schema';
import { selectUser, updateUserLocaly } from 'src/store/user/user.slice';
import { useDispatch, useSelector } from 'react-redux';
import { setWhatsappSettings } from 'src/common/api/channels-settings';
import { useMutation } from '@tanstack/react-query';
import { Button } from 'src/ui/Button/Button';
import { FaCircleInfo } from 'react-icons/fa6';
import { catchError } from 'src/common/utils/catchError';
import { ROUTES } from 'src/router/routes';
import { useTutorialNavigation } from 'src/common/hooks/useTutorialNavigations';
import { CopyInput } from 'src/ui/CopyInput/CopyInput';
import { nextStep } from 'src/store/onboarding/onboarding.slice';
import { getObjectFromStorage, saveToStorageOnBlur } from 'src/common/utils';
import { MessengerType } from 'src/common/interfaces';

const WhatsappForm = () => {
  const { t } = useTranslation();

  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const { showTutorialInformation } = useTutorialNavigation();

  const { setSearchParams } = useSearchParams();

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<WhatsappSettingsInputs>({
    resolver: zodResolver(whatsappSettingsSchema),
  });

  const { mutate: saveWhatsappSettings } = useMutation({
    mutationFn: async (whatsappSettings: WhatsappSettingsInputs) => {
      return setWhatsappSettings(whatsappSettings);
    },
    onSuccess: data => {
      dispatch(updateUserLocaly({ expert: { settings: { whatsappSettings: data } } }));
      handleGoForward();
    },
    onError: e => {
      catchError(e);
    },
  });

  useEffect(() => {
    const data = getObjectFromStorage<WhatsappSettingsInputs>(MessengerType.WHATSAPP);

    reset({
      accessToken: user?.expert?.settings?.whatsappSettings?.accessToken || data?.accessToken || undefined,
      phoneNumberId: user?.expert?.settings?.whatsappSettings?.phoneNumberId || data?.phoneNumberId || undefined,
      phone: user?.expert?.settings?.whatsappSettings?.phone || data?.phone || undefined,
    });
  }, [user]);

  const handleGoBack = () => {
    setSearchParams({ channel: '' });
  };

  const handleGoForward = () => {
    dispatch(nextStep(1));
  };

  const onSubmit = (vals: WhatsappSettingsInputs) => {
    if (!isDirty) handleGoBack();
    saveWhatsappSettings(vals);
  };

  const watcher = useWatch({ control });

  const isFormDisabled = useMemo(() => {
    const { accessToken, phone, phoneNumberId } = watcher;

    return isDirty
      ? !accessToken || !phone || !phoneNumberId
      : !user?.expert?.settings?.whatsappSettings?.accessToken ||
          !user?.expert?.settings?.whatsappSettings?.phoneNumberId ||
          !user?.expert?.settings?.whatsappSettings?.phone;
  }, [watcher, user?.expert?.settings?.instagramSettings, isDirty]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
      <div className='space-y-2'>
        <div className='flex items-center justify-between'>
          <Label htmlFor='accessToken'>{t('onboarding.channels.whatsapp.accessToken')}</Label>
          <Button
            className='text-primary'
            type='button'
            onClick={() => {
              showTutorialInformation(ROUTES.WHATSAPP_TUTORIAL, '4');
            }}
          >
            <FaCircleInfo className='h-4 w-4' />
            {t('onboarding.channels.whatsapp.tip.accessToken')}
          </Button>
        </div>
        <Controller
          name='accessToken'
          control={control}
          render={({ field }) => {
            return (
              <Textarea
                id='accessToken'
                {...field}
                rows={4}
                error={errors.accessToken?.message}
                onBlur={e => saveToStorageOnBlur(e, MessengerType.WHATSAPP)}
              />
            );
          }}
        />
      </div>
      <div className='space-y-2'>
        <div className='flex items-center justify-between'>
          <Label htmlFor='phoneNumberId'>{t('onboarding.channels.whatsapp.phoneNumberId')}</Label>
          <Button
            className='text-primary'
            type='button'
            onClick={() => {
              showTutorialInformation(ROUTES.WHATSAPP_TUTORIAL, '4');
            }}
          >
            <FaCircleInfo className='h-4 w-4' />
            {t('onboarding.channels.whatsapp.tip.phoneNumberId')}
          </Button>
        </div>
        <Controller
          name='phoneNumberId'
          control={control}
          render={({ field }) => {
            return (
              <TextInput
                className='h-[50px]'
                id='phoneNumberId'
                {...field}
                onBlur={e => saveToStorageOnBlur(e, MessengerType.WHATSAPP)}
                error={errors.phoneNumberId?.message}
              />
            );
          }}
        />
      </div>
      <div className='space-y-2'>
        <Label htmlFor='phone'>{t('onboarding.channels.whatsapp.phone')}</Label>
        <Controller
          name='phone'
          control={control}
          render={({ field }) => {
            return (
              <TextInput
                className='h-[50px]'
                id='phone'
                {...field}
                error={errors.phone?.message}
                onBlur={e => saveToStorageOnBlur(e, MessengerType.WHATSAPP)}
                onChange={e => field.onChange(e.target.value.replaceAll(' ', ''))}
              />
            );
          }}
        />
      </div>
      <div className='space-y-2'>
        <CopyInput
          subUrl='whatsapp'
          i18nKey='onboarding.channels.webhook.subheader.whatsapp'
          subheader={
            <span
              role='button'
              className='cursor-pointer text-primary'
              onClick={() => showTutorialInformation(ROUTES.WHATSAPP_TUTORIAL, '3')}
            ></span>
          }
        />
      </div>
      <div className='flex items-center !mt-[30px]'>
        <Button variant={'primary'} disabled={isFormDisabled}>
          {t('onboarding.common.saveButton')}
        </Button>
        <Button
          type='button'
          className='h-[36px] w-[70px] px-[15px] py-[5px] bg-light text-grey-600 rounded-[6px] ml-auto'
          onClick={() => {
            handleGoBack();
          }}
        >
          {t('onboarding.common.backButton')}
        </Button>
      </div>
    </form>
  );
};

export { WhatsappForm };
