import { AiChatMessage } from 'src/common/interfaces/ai-chat';
import { create } from 'zustand';

type StoreAiChatMessage = Omit<AiChatMessage, 'id' | 'aiChatId' | 'createdAt' | 'updatedAt'> & {
  id: React.Key;
  createdAt: string | Date;
  updatedAt: string | Date;
  isGenerating?: boolean;
  isLast?: boolean;
};

type AiChatStore = {
  messages: StoreAiChatMessage[];
  addMessage: (message: StoreAiChatMessage) => void;
  setMessages: (messages: StoreAiChatMessage[]) => void;
  removeMessage: (messageId: React.Key) => void;
  setMessageGeneratingState: (messageId: React.Key) => void;
  refreshAiMessage: (message: AiChatMessage) => void;
};

const useAiChatStore = create<AiChatStore>((set, get) => ({
  messages: [],
  addMessage: message =>
    set(state => ({
      messages: [
        ...state.messages.map(m => ({
          ...m,
          isLast: false,
        })),
        {
          ...message,
          isLast: true,
        },
      ],
    })),
  setMessages: messages =>
    set(() => ({
      messages:
        messages && messages.length
          ? [
              ...messages.slice(0, messages.length - 1),
              {
                ...messages[messages.length - 1],
                isLast: true,
              },
            ]
          : [],
    })),
  removeMessage: id =>
    set(() => {
      const { messages } = get();

      return {
        messages: messages.filter(message => message.id !== id),
      };
    }),
  setMessageGeneratingState: id => {
    set(() => {
      const { messages } = get();

      return {
        messages: messages.map(message =>
          message.id === id ? { ...message, isGenerating: true } : message,
        ),
      };
    });
  },
  refreshAiMessage: refreshedMessage => {
    set(() => {
      const { messages } = get();

      return {
        messages: messages.map(message =>
          message.id === refreshedMessage.id
            ? {
                ...refreshedMessage,
                isLast: true,
              }
            : message,
        ),
      };
    });
  },
}));

export { useAiChatStore };

export type { AiChatStore, StoreAiChatMessage };
