import React, { type RefObject, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextInput } from 'src/ui/TextInput/TextInput';
import { useForm, Controller } from 'react-hook-form';
import { AppStateType } from 'src/store';
import * as z from 'zod';
import { Trans, useTranslation } from 'react-i18next';
import { FieldError } from 'src/components/FieldError';
import { nextStep } from 'src/store/onboarding/onboarding.slice';
import { paymentSchema } from 'src/common/utils/schema/payments.schema';
import { FaInfoCircle } from 'react-icons/fa';
import { usStates } from 'src/common/data';
import { DatePicker } from 'src/ui/DatePicker/DatePicker';
import { useMutation } from '@tanstack/react-query';
import { UserInterface, updateUserLocaly } from 'src/store/user/user.slice';
import { ReducedPaymentSettingsInterface, updatePaymentSettings } from 'src/common/api/payments';
import { useToast } from 'src/ui/Toast/use-toast';
import { DropDown } from 'src/ui/DropDownRadix/DropDown';

type Inputs = z.infer<typeof paymentSchema>;

const PaymentsForm = ({
  paymentButtonRef,
  resetButtonref,
  handleSubmit,
  reset,
  control,
  formState: { errors },
  setIsLoading,
  showToast = false,
}: {
  paymentButtonRef: RefObject<HTMLButtonElement>;
  resetButtonref?: RefObject<HTMLButtonElement>;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  showToast?: boolean;
} & ReturnType<typeof useForm<Inputs>>) => {
  const { toast } = useToast();
  const { user } = useSelector((state: AppStateType) => state.user);

  const { mutate: update } = useMutation({
    mutationFn: (data: ReducedPaymentSettingsInterface) => updatePaymentSettings(data),
    onMutate: () => {
      setIsLoading?.(true);
    },
    onSuccess: (data: UserInterface) => {
      setIsLoading?.(false);
      dispatch(updateUserLocaly(data));
      showToast && toast({ title: 'Payments data successfully updated!', variant: 'success' });
      dispatch(nextStep(1));
    },
    onError: (error: Error) => {
      setIsLoading?.(false);
      toast({ title: error.message, variant: 'destructive' });
    },
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (user?.expert?.paymentSettings) {
      onReset();
    }
  }, [user?.expert?.paymentSettings]);

  const onReset = () => {
    const date = user?.expert?.paymentSettings?.birthday
      ? new Date(user?.expert?.paymentSettings?.birthday)
      : null;
    date?.setUTCHours(0, 0, 0, 0);

    reset({
      ...user?.expert?.paymentSettings,
      routingNumber: user?.expert?.paymentSettings?.routingNumber || '',
      birthday: {
        ...(date && {
          date: date.getDate(),
          month: date.getMonth(),
          year: date.getFullYear(),
        }),
      },
      state: user?.expert?.paymentSettings?.state || undefined,
    });
  };

  const onSubmit = (vals: Inputs) => {
    const birthday = new Date(
      Date.UTC(vals.birthday.year, vals.birthday.month, vals.birthday.date),
    );
    birthday.setUTCHours(0, 0, 0, 0);

    update({
      ...vals,
      routingNumber: vals.routingNumber || '',
      addrLine2: vals.addrLine2 || '',
      id: user!.id,
      birthday: birthday.toUTCString(),
      useCommonAccount: false,
    });
  };

  return (
    <form
      className='flex flex-col gap-8'
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => onReset()}
    >
      <div className='flex gap-[30px] flex-col'>
        <div className='w-full'>
          <h3 className='header-3 mb-[15px]'>
            {t('onboarding.payments.form.accountNumber.title')}
          </h3>
          <Controller
            name='accountNumber'
            control={control}
            render={({ field }) => (
              <TextInput
                className='h-[50px]'
                type='input'
                id='accountNumber'
                {...field}
                value={field.value}
                error={errors.accountNumber?.message}
              />
            )}
          />
        </div>
        <div className='w-full'>
          <div className='flex justify-between mb-[15px]'>
            <h3 className='header-3'>{t('onboarding.payments.form.routingNumber.title')}</h3>
            <div className='flex gap-[10px] items-center'>
              <FaInfoCircle className='w-[16px] h-[16px] text-grey-500' />
              <div className='text-grey-900 text-[14px] leading-[22px]'>
                {t('onboarding.payments.form.routingNumber.tooltip')}
              </div>
            </div>
          </div>
          <Controller
            name='routingNumber'
            control={control}
            render={({ field }) => (
              <TextInput
                className='h-[50px]'
                type='input'
                id='routingNumber'
                {...field}
                error={errors.routingNumber?.message}
              />
            )}
          />
          <div className='mt-[15px] text-[14px] leading-[22px] text-grey-500 opacity-100'>
            {t('onboarding.payments.form.routingNumber.hint')}
          </div>
        </div>
        <div className='w-full'>
          <h3 className='header-3 mb-[15px]'>{t('onboarding.payments.form.taxId.title')}</h3>
          <Controller
            name='taxID'
            control={control}
            render={({ field }) => (
              <TextInput
                id='taxID'
                className='h-[50px]'
                type='input'
                {...field}
                error={errors.taxID?.message}
              />
            )}
          />
        </div>
        <div className='w-full'>
          <h3 className='header-3 mb-[15px]'>{t('onboarding.payments.form.phoneNumber.title')}</h3>
          <Controller
            name='phoneNumber'
            control={control}
            render={({ field }) => (
              <TextInput
                id='phoneNumber'
                className='h-[50px]'
                type='input'
                {...field}
                error={errors.phoneNumber?.message}
              />
            )}
          />
        </div>
        <div className='w-full'>
          <h3 className='header-3 mb-[15px]'>{t('onboarding.payments.form.birthday.title')}</h3>
          <Controller
            name='birthday'
            control={control}
            render={({ field }) => (
              <DatePicker
                date={field.value?.date}
                maxAge={100}
                minAge={18}
                month={field.value?.month}
                year={field.value?.year}
                setDate={value => {
                  field.onChange(value);
                }}
              />
            )}
          />
          <FieldError
            error={
              errors.birthday?.date?.message ||
              errors.birthday?.month?.message ||
              errors.birthday?.year?.message
            }
            className='ml-0 mt-2'
          />
        </div>
        <div>
          <h3 className='header-3 mb-[15px]'>
            {t('onboarding.payments.form.billingAddress.title')}
          </h3>
          <div className='w-full flex flex-col gap-[15px]'>
            <div>
              <Controller
                name='city'
                control={control}
                render={({ field }) => (
                  <TextInput
                    className='h-[50px]'
                    placeholder={t('onboarding.payments.form.billingAddress.city.placeholder')}
                    type='input'
                    id='city'
                    error={errors.city?.message}
                    {...field}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name='addrLine1'
                control={control}
                render={({ field }) => (
                  <TextInput
                    className='h-[50px]'
                    placeholder={t('onboarding.payments.form.billingAddress.addrLine1.placeholder')}
                    type='input'
                    id='addrLine1'
                    error={errors.addrLine1?.message}
                    {...field}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name='addrLine2'
                control={control}
                render={({ field }) => (
                  <TextInput
                    className='h-[50px]'
                    placeholder={t('onboarding.payments.form.billingAddress.addrLine2.placeholder')}
                    type='input'
                    id='addrLine2'
                    {...field}
                    value={field.value ?? ''}
                    error={errors.addrLine2?.message}
                  />
                )}
              />
            </div>
            <div className='flex flex-row gap-[15px] w-full'>
              <div className='w-[calc(50%-7.5px)]'>
                <Controller
                  name='postCode'
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      className='h-[50px]'
                      placeholder={t(
                        'onboarding.payments.form.billingAddress.postCode.placeholder',
                      )}
                      type='input'
                      id='postCode'
                      error={errors.postCode?.message}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='w-[calc(50%-7.5px)]'>
                <Controller
                  name='state'
                  control={control}
                  render={({ field }) => (
                    <DropDown
                      options={usStates}
                      onValueChange={v => v && field.onChange(v)}
                      value={field.value}
                      name='state'
                      placeholder='State'
                      className='text-[16px] h-[50px]'
                    />
                  )}
                />
                <FieldError error={errors.state?.message} className='ml-0 mt-2' />
              </div>
            </div>
          </div>
        </div>
        <div className='text-[14px]'>
          <Trans
            i18nKey={'onboarding.payments.agreement'}
            components={{
              1: (
                <a
                  rel='noreferrer'
                  target='_blank'
                  href='https://stripe.com/legal/ssa'
                  className='text-primary'
                />
              ),
            }}
          />
        </div>
      </div>
      <button type='submit' className='hidden' ref={paymentButtonRef} />
      {resetButtonref ? <button type='reset' ref={resetButtonref} /> : null}
    </form>
  );
};

export { PaymentsForm };
