import { fetchBuilder } from 'src/services/basicFetch';
import { MethodEnum } from '../enums';

const deleteAvatar = async (token: string, id: string | number) => {
  return fetchBuilder({ token }, `user/avatar`, MethodEnum.DELETE);
};

const deleteBrandingLogo = (token: string, id: string | number) => {
  return fetchBuilder(
    {
      token,
    },
    `expert/${id}/logo`,
    MethodEnum.DELETE,
  );
};

const uploadAvatar = (token: string, id: string | number, userAvatar: File | 'DELETED') => {
  if (userAvatar === 'DELETED') return deleteAvatar(token, id);

  return fetchBuilder(
    {
      token,
    },
    `user/avatar`,
    MethodEnum.PATCH,
    [userAvatar],
  );
};

const uploadBrandingLogo = (token: string, id: string | number, brandLogo: File | 'DELETED') => {
  if (brandLogo === 'DELETED') return deleteBrandingLogo(token, id);

  return fetchBuilder(
    {
      token,
    },
    `expert/${id}/logo`,
    MethodEnum.PATCH,
    [brandLogo],
  );
};

export { deleteAvatar, deleteBrandingLogo, uploadAvatar, uploadBrandingLogo };
