/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { FaCheck as CheckIcon, FaChevronDown } from 'react-icons/fa6';
import { FaTimes as CloseIcon } from 'react-icons/fa';

import * as SelectPrimitive from '@radix-ui/react-select';
import { cn } from 'src/common/utils';
import { Button } from '../Button/Button';
import { boolean } from 'zod';

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      `flex justify-between items-center 
            relative w-full
            px-[15px] py-[15px]
            rounded-md
            bg-light
            focused: outline-none
            cursor-pointer disabled:opacity-80 relative`,
      className,
    )}
    {...props}
  >
    {children}

    <div className='flex gap-2 items-center'>
      <SelectPrimitive.Icon asChild>
        <FaChevronDown className='text-grey-500' />
      </SelectPrimitive.Icon>
    </div>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        'relative z-50 max-h-96 min-w-[8rem] overflow-y-scroll rounded-md border bg-light text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 outline-none border-none',
        position === 'popper' &&
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        className,
      )}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport
        className={cn(
          'p-0 pb-0 m-0',
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn('px-2 py-1.5 text-sm font-semibold', className)}
    {...props}
  />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & { isHidden?: boolean }
>(({ className, children, isHidden, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      'relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2 pr-8 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 text-grey-900 px-4 py-2',
      {
        hidden: isHidden,
      },
      className,
    )}
    {...props}
  >
    <span className='absolute right-2 flex h-3.5 w-3.5 items-center justify-center'>
      <SelectPrimitive.ItemIndicator>
        <CheckIcon className='h-4 w-4 text-grey-500' />
      </SelectPrimitive.ItemIndicator>
    </span>
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 my-1 h-px bg-muted', className)}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
};

export interface Option {
  text: string;
  value: string;
}

export interface DropDownProps {
  options: Option[];
  value?: string;
  name: string;
  defaultValue?: string;
  onValueChange?(value: string): void;
  placeholder?: string;
  className?: string;
  contentClassName?: string;
  filterMode?: boolean;
  disabled?: boolean;
  resetClassName?: string;
}

export const DropDown: React.FC<DropDownProps> = ({
  options,
  onValueChange,
  value: currentValue,
  name,
  placeholder,
  className,
  contentClassName,
  defaultValue,
  filterMode,
  disabled = false,
  resetClassName,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Select
      onValueChange={onValueChange}
      value={currentValue}
      name={name}
      defaultValue={defaultValue}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <div className='secondary relative w-full'>
        <SelectTrigger
          onPointerDown={e => e.preventDefault()}
          onClick={() => setIsOpen(prev => !prev)}
          className={cn(
            className,
            'data-[placeholder]:text-grey-500 text-[14px] py-[9px] px-[15px]',
          )}
          disabled={disabled}
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        {filterMode && currentValue && (
          <Button
            onClick={e => {
              onValueChange?.('');
            }}
            className={cn(
              'text-grey-500 absolute top-[10px] right-10 cursor-pointer hover:text-grey-400',
              resetClassName,
            )}
          >
            <CloseIcon className='w-5 h-5' />
          </Button>
        )}
      </div>
      <SelectContent className={cn('outline-none bg-white py-0 mb-0 px-0')}>
        {options.map(({ text, value }, idx) => (
          <SelectItem
            key={idx}
            value={value}
            isHidden={currentValue === value}
            className={cn(
              'hover:text-primary hover:cursor-pointer hover:bg-primary-light text-[14px] m-0 px-[14px] py-[8px]',
              contentClassName,
            )}
          >
            {text}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
