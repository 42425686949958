import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useOnboardingStatus, useScreenDetect } from 'src/common/hooks';
import { ROUTES } from 'src/router/routes';
import { Button } from 'src/ui/Button/Button';

function EmptyChatPage() {
  const { t } = useTranslation();
  const { isTargetScreen } = useScreenDetect('mobile');
  const { handleBackToOnboarding, status } = useOnboardingStatus();

  if (isTargetScreen) return <></>;

  return (
    <div className='w-full min-h-screen flex items-center bg-chat-bg'>
      <div className='flex w-full justify-center'>
        <div className='max-w-[600px] flex flex-col gap-6 text-center items-center'>
          <h1 className='text-[64px]'>{status.messengerStatus.completed ? '💬' : '📢'}</h1>
          {!status.messengerStatus.completed ? (
            <>
              <p className='text-md md:text-lg'>{t('chats.empty.subtitle')}</p>
              <Button
                variant='primary'
                className='px-[30px]'
                onClick={() => handleBackToOnboarding()}
              >
                {t('chats.empty.button')}
              </Button>
            </>
          ) : (
            <p>
              <Trans
                i18nKey={'chats.empty.noChats'}
                components={{
                  1: <Link to={`/${ROUTES.LINKS}`} className='inline text-primary' />,
                }}
              />
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmptyChatPage;
