import { create } from 'zustand';
import { Chat, ChatStatus, MessageVariant, MesssageWithChat } from 'src/common/interfaces';
import { useMemo } from 'react';
import { Dictionary, groupBy, orderBy, sortBy } from 'lodash';
import { extractFromArray } from 'src/common/utils';

type ActiveChatInfo = Omit<Chat, 'expert'>;

type ChatStore = {
  chats: Chat[];
  activeChatInfo: ActiveChatInfo | null;
  setUserChats: (chats: Chat[]) => void;
  setActiveChat: (activeChat: ActiveChatInfo | null) => void;
  addNewMessage: (message: MesssageWithChat) => void;
  resetUnreadCount: (chatId: string) => void;
  handleChatApprove: (chatId: string, message: MesssageWithChat) => void;
  handleChatReject: (chatId: string, message: MesssageWithChat) => void;
  handleStatusChange: (chatId: string, status: ChatStatus) => void;
};

const useChatStore = create<ChatStore>((set, get) => ({
  chats: [],
  setUserChats: chats =>
    set(state => ({
      chats,
    })),
  activeChatInfo: null,
  setActiveChat: activeChatInfo => set(state => ({ activeChatInfo })),
  addNewMessage: message =>
    set(state => {
      const { chats, activeChatInfo } = get();
      let refreshedChats;

      if (message.variant !== MessageVariant.CONVERSATION_COMPLETED) {
        const { newArray: sortedChats, value: targetChat } = extractFromArray(
          chats,
          message.chat.id,
          'id',
        );
        if (targetChat) {
          refreshedChats = [
            {
              ...targetChat,
              messages: [message],
              unreadCount: message.chat.unreadCount,
              updatedAt: message.updatedAt,
            },
            ...sortedChats,
          ];
        } else {
          refreshedChats = sortedChats;
        }
      } else {
        refreshedChats = chats.map(chat =>
          chat.id === message.chat.id
            ? {
                ...chat,
                unreadCount: message.chat.unreadCount,
              }
            : chat,
        );
      }

      const messageFromActiveChat = message.chat.id === activeChatInfo?.id;

      const newActiveMessages = [...(activeChatInfo?.messages ?? [])];

      if (messageFromActiveChat) newActiveMessages.push(message);

      if (activeChatInfo) {
        return {
          ...state,
          chats: refreshedChats,
          activeChatInfo: {
            ...activeChatInfo,
            messages: newActiveMessages,
          },
        };
      }

      return {
        ...state,
        activeChatInfo,
        chats: refreshedChats,
      };
    }),
  resetUnreadCount: chatId =>
    set(state => {
      const { chats } = get();

      return {
        ...state,
        chats: chats.map(chat => (chat.id === chatId ? { ...chat, unreadCount: 0 } : chat)),
      };
    }),
  handleChatApprove: (chatId, message) =>
    set(state => {
      const { chats, activeChatInfo } = get();

      return {
        ...state,
        activeChatInfo,
        chats: chats.map(chat =>
          chat.id === chatId
            ? {
                ...chat,
                messages: [message],
                status: ChatStatus.PAYMENT_SENT,
                updatedAt: new Date(Date.now()).toISOString(),
              }
            : chat,
        ),
      };
    }),
  handleChatReject: (chatId, message) =>
    set(state => {
      const { chats, activeChatInfo } = get();

      return {
        ...state,
        activeChatInfo,
        chats: chats.map(chat =>
          chat.id === chatId ? { ...chat, messages: [message], status: ChatStatus.CLOSED } : chat,
        ),
      };
    }),
  handleStatusChange: (chatId, status) =>
    set(state => {
      const { chats, activeChatInfo } = get();

      const chat = chats.find(chat => chat.id === chatId);

      if (!chat) return state;

      const fromActiveChat = chat.id === activeChatInfo?.id;

      if (fromActiveChat && activeChatInfo) {
        return {
          ...state,
          activeChatInfo: {
            ...activeChatInfo,
            status,
          },
          chats: chats.map(chat => (chat.id === chatId ? { ...chat, status } : chat)),
        };
      }

      const { newArray: sortedChats, value: targetChat } = extractFromArray(chats, chatId, 'id');

      [];

      return {
        ...state,
        chats: targetChat ? [targetChat, ...sortedChats] : chats,
      };
    }),
}));

const useChatHookStore = () => {
  const store = useChatStore();

  const {
    active: activeChats,
    requested: requestedChats,
    closed: closedChats,
  } = useMemo(() => {
    const chats = groupBy(store.chats, 'status');

    const unorderedActive = store.chats.filter(ch => ch.status !== ChatStatus.CLOSED);
    const active = orderBy(unorderedActive, 'updatedAt', 'desc');

    return { active, requested: chats?.requested ?? [], closed: chats?.closed ?? [] } as Dictionary<
      Chat[]
    >;
  }, [store.chats]);

  const [inboxCount, requestsCount] = useMemo(() => {
    const inboxCount = activeChats?.reduce((acc, { unreadCount }) => acc + unreadCount, 0);

    const requestsCount = requestedChats.length;

    return [inboxCount, requestsCount];
  }, [activeChats, requestedChats]);

  return {
    ...store,
    inboxCount,
    requestsCount,
    activeChats,
    requestedChats,
    closedChats,
  };
};

export { useChatHookStore as useChatStore };
