import { toast } from 'src/ui/Toast/use-toast';

const catchError = (err: unknown, key?: React.Key, title?: string) => {
  return toast({
    variant: 'destructive',
    title: title || 'Request failed',
    description: (err as Error)?.message,
    key,
  });
};

export { catchError };
