import React, { useContext, useEffect, useMemo, useRef } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams as useRRSearchParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppStateType } from './store';
import { ROUTES } from './router/routes';

import { MessagesPage } from './pages/Messages.page';
import MyLinksPage from './pages/My-links/MyLinks.page';
import { TransactionsPage } from './pages/Transactions/Transactions.page';

import { MenuBar, MobileMenuBar } from './ui/MenuBar/MenuBar';
import { FaAddressCard, FaCog, FaComments, FaMoneyBill, FaTachometerAlt } from 'react-icons/fa';
import { SocketContext } from './context/socket.context';
import ChatPage from './pages/Chat/Chat';
import { ProfilePage } from './pages/Profile/Profile.page';
import { BusinessSettings } from './components/BusinessSettings';
import BusinessCardPage from './pages/Business-card/BusinessCard.page';
import { useQuery } from '@tanstack/react-query';
import { SubscriptionsPage } from './pages/Subscriptions/Subscriptions.page';
import EmptyChatPage from './pages/Chat/EmptyChatPage';
import { OfferPage } from './pages/Offer/Offer.page';
import { useChatStore } from './store/chat/chatStore';
import { getChats } from './common/api/chat';
import { ChatStatus, MesssageWithChat } from './common/interfaces';
import { PaymentPage } from './pages/Payments/Payments.page';
import AIHelperSettingsPage from './pages/AI-helper-settings/AIHelperSettings';
import { ClientChat } from './pages/Chat/ClientChat/ClientChat';
import { AiChat } from './pages/Chat/AiChat/AiChat';

import { useSearchParams } from './common/hooks';
import { NotificationsPage } from './pages/Notifications/Notifications.page';
import { SeoProvider } from './App';
import { useBrowserNotificationsStore } from './store/notifications/useBrowserNotificationsStore';
import favicon from 'src/icons/app.png';
import { usePushNotificationsStore } from './store/notifications/usePushNotificationsStore';
import CommunicationChannelsPage from './pages/Communication-channels/CommunicationChannels.page';
import { useTranslation } from 'react-i18next';
import WhatsappSettingsPage from './pages/Communication-channels/Whatsapp/WhatsappSettings.page';
import MessengerSettingsPage from './pages/Communication-channels/Messenger/MessengerSettings.page';
import InstagramSettingsPage from './pages/Communication-channels/Instagram/InstagramSettings.page';
import { DashboardPage } from './pages/Dashboard/Dashboard.page';

const MainLayout = () => {
  const { t } = useTranslation();

  const { user } = useSelector((state: AppStateType) => state.user);

  const location = useLocation();

  const navigate = useNavigate();
  const socket = useContext(SocketContext);

  const {
    handleStatusChange,
    setUserChats,
    addNewMessage,
    inboxCount,
    requestsCount,
    activeChatInfo,
  } = useChatStore();

  const { setNewSearchParams } = useSearchParams();

  const { spawnNotification, isTabActive } = useBrowserNotificationsStore();

  usePushNotificationsStore();
  useNotificationsIndicator();

  const { refetch: getAllChats } = useQuery(['chats'], getChats, {
    onSuccess: data => {
      setUserChats(data);
    },
    cacheTime: 0,
  });

  const { items, mobileItems } = useMemo(() => {
    return {
      items: [
        {
          tPath: 'menuBar.dashboard',
          icon: FaTachometerAlt,
          route: ROUTES.DASHBOARD,
        },
        {
          tPath: 'menuBar.chats',
          icon: FaComments,
          route: ROUTES.CHATS,
          counter: inboxCount + requestsCount,
        },
        {
          tPath: 'menuBar.transactions',
          icon: FaMoneyBill,
          route: ROUTES.TRANSACTIONS,
        },
        {
          tPath: 'menuBar.myLinks',
          icon: FaAddressCard,
          route: ROUTES.LINKS,
        },
        {
          tPath: 'menuBar.businessSettings',
          icon: FaCog,
          route: ROUTES.SETTINGS,
          component: <BusinessSettings />,
        },
      ],
      mobileItems: [
        {
          tPath: 'menuBar.dashboard',
          icon: FaTachometerAlt,
          route: ROUTES.DASHBOARD,
        },
        {
          tPath: 'menuBar.transactions',
          icon: FaMoneyBill,
          route: ROUTES.TRANSACTIONS,
        },
        {
          tPath: 'menuBar.chats',
          icon: FaComments,
          route: ROUTES.CHATS,
          counter: inboxCount + requestsCount,
        },
        {
          tPath: 'menuBar.myLinks',
          icon: FaAddressCard,
          route: ROUTES.LINKS,
        },
      ],
    };
  }, [inboxCount, requestsCount]);

  useEffect(() => {
    socket.on(
      'receiveMessage',
      (data: MesssageWithChat & { clientName?: string; clientAvatarUrl?: string }) => {
        addNewMessage(data);
        if (!isTabActive && data?.clientName) {
          const attachmentsCount = Number(data.attachments?.length);

          spawnNotification(data.clientName, {
            body:
              data.body ||
              `${attachmentsCount} ${
                attachmentsCount > 1
                  ? t('chats.tips.attachment.plural')
                  : t('chats.tips.attachment.single')
              }`,
            icon: data.clientAvatarUrl || favicon,
            data: `${window.origin}/${ROUTES.CHATS}/${data.chat.id}`,
          });
        }
      },
    );

    socket.on('newChat', (data: { id: string; clientName?: string; clientAvatarUrl?: string }) => {
      getAllChats();

      if (!isTabActive) {
        spawnNotification(t('browserNotifications.newRequest'), {
          body: data.clientName,
          icon: data.clientAvatarUrl || favicon,
          data: `${window.origin}/${ROUTES.CHATS}/${data.id}`,
        });
      }
    });

    return () => {
      socket.off('newChat');

      socket.off('receiveMessage');
    };
  }, [isTabActive]);

  useEffect(() => {
    socket.on(
      'statusChange',
      (data: {
        chatId: string;
        status: ChatStatus;
        clientName?: string;
        clientAvatarUrl?: string;
      }) => {
        handleStatusChange(data.chatId, data.status);
        if (data.chatId === activeChatInfo?.id) {
          setNewSearchParams({
            chatStatus:
              data.status !== ChatStatus.CLOSING &&
              data.status !== ChatStatus.CLOSING_DETAILS_PROVIDE &&
              data.status !== ChatStatus.CLOSING_NOTIFIED
                ? data.status
                : ChatStatus.ACTIVE,
          });
        }
      },
    );

    return () => {
      socket.off('statusChange');
    };
  }, [activeChatInfo?.id]);

  useEffect(() => {
    socket.on('failedMessage', (data: MesssageWithChat) => {
      console.log('failedMessage', data);
    });

    return () => {
      socket.off('failedMessage');
    };
  }, []);

  useEffect(() => {
    if (user?.id) {
      socket.emit('online', user.id);
    }

    return () => {
      if (user?.id) {
        socket.emit('offline', user.id);
      }
    };
  }, [user?.id]);

  useEffect(() => {
    if (user && !user.hadUserOnboarding && !location.pathname.startsWith(ROUTES.TUTORIAL)) {
      navigate(`/${ROUTES.ONBOARDING}`);
    }
  }, [user?.hadUserOnboarding, location]);

  return (
    <div className='flex min-h-screen'>
      <MenuBar
        className='hidden lg:flex'
        userImage={user?.avatarUrl || ''}
        navigationItems={items}
        username={`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}
        email={user?.email}
      />
      <Routes>
        <Route path='/' element={<Navigate to={`/${ROUTES.DASHBOARD}`} />}></Route>
        <Route
          path={ROUTES.DASHBOARD}
          element={
            <SeoProvider title='Dashboard' description={'Expert dashboard page.'} name={'Anamata'}>
              <DashboardPage />
            </SeoProvider>
          }
        />
        <Route
          path={ROUTES.PROFILE}
          element={
            <SeoProvider title='My Profile' description={'Expert profile page.'} name={'Anamata'}>
              <ProfilePage />
            </SeoProvider>
          }
        />
        <Route
          path={ROUTES.CHATS}
          element={
            <SeoProvider title='Your messages' description={'Expert chats page.'} name={'Anamata'}>
              <MessagesPage />
            </SeoProvider>
          }
        >
          <Route index element={<EmptyChatPage />} />
          <Route path={`/${ROUTES.CHATS}/:chatId`} element={<ChatPage />}>
            <Route index element={<ClientChat />} />
            <Route path={ROUTES.AI_CHAT} element={<AiChat />} />
          </Route>
        </Route>
        <Route
          path={ROUTES.BUSINESS_CARD}
          element={
            <SeoProvider
              title='Business card'
              description={'Expert business card page.'}
              name={'Anamata'}
            >
              <BusinessCardPage />
            </SeoProvider>
          }
        />
        <Route
          path={ROUTES.COMMUNICATION_CHANNELS}
          element={
            <SeoProvider
              title='Communication channels'
              description={'Expert communication channels page.'}
              name={'Anamata'}
            >
              <CommunicationChannelsPage />
            </SeoProvider>
          }
        />
        <Route
          path={`${ROUTES.COMMUNICATION_CHANNELS}/${ROUTES.WHATSAPP_SETTINGS}`}
          element={
            <SeoProvider
              title='Whatsapp settings'
              description={'Expert whatsapp communication settings page.'}
              name={'Anamata'}
            >
              <WhatsappSettingsPage />
            </SeoProvider>
          }
        />
        <Route
          path={`${ROUTES.COMMUNICATION_CHANNELS}/${ROUTES.MESSENGER_SETTINGS}`}
          element={
            <SeoProvider
              title='Messenger settings'
              description={'Expert messenger communication settings page.'}
              name={'Anamata'}
            >
              <MessengerSettingsPage />
            </SeoProvider>
          }
        />
        <Route
          path={`${ROUTES.COMMUNICATION_CHANNELS}/${ROUTES.INSTAGRAM_SETTINGS}`}
          element={
            <SeoProvider
              title='Instagram settings'
              description={'Expert instagram communication settings page.'}
              name={'Anamata'}
            >
              <InstagramSettingsPage />
            </SeoProvider>
          }
        />
        <Route
          path={ROUTES.LINKS}
          element={
            <SeoProvider title='My Links' description={'Expert links page.'} name={'Anamata'}>
              <MyLinksPage />
            </SeoProvider>
          }
        />
        <Route
          path={ROUTES.TRANSACTIONS}
          element={
            <SeoProvider
              title='Transactions'
              description={'Expert transactions page.'}
              name={'Anamata'}
            >
              <TransactionsPage />
            </SeoProvider>
          }
        />
        <Route
          path={ROUTES.SUBSCRIPTIONS}
          element={
            <SeoProvider
              title='Subscriptions'
              description={'Expert subscriptions page.'}
              name={'Anamata'}
            >
              <SubscriptionsPage />
            </SeoProvider>
          }
        />
        <Route
          path={ROUTES.OFFER}
          element={
            <SeoProvider title='Offer' description={'Offer page.'} name={'Anamata'}>
              <OfferPage />
            </SeoProvider>
          }
        />
        <Route
          path={ROUTES.PAYMENTS}
          element={
            <SeoProvider title='Payments' description={'Payments page'} name={'Anamata'}>
              <PaymentPage />
            </SeoProvider>
          }
        />
        <Route
          path={ROUTES.AI_HELPER_SETTINGS}
          element={
            <SeoProvider
              title='AI helper settings'
              description={'AI helper settings page.'}
              name={'Anamata'}
            >
              <AIHelperSettingsPage />
            </SeoProvider>
          }
        />
        <Route
          path={ROUTES.NOTIFICATIONS}
          element={
            <SeoProvider
              title='Notification settings'
              description={'Notification settings page.'}
              name={'Anamata'}
            >
              <NotificationsPage />
            </SeoProvider>
          }
        />
      </Routes>
      <MobileMenuBar
        className='lg:hidden fixed bottom-0'
        userImage={user?.avatarUrl || ''}
        navigationItems={mobileItems}
        username={`${user?.firstName} ${user?.lastName}`}
        email={user?.email}
      />
    </div>
  );
};

const useNotificationsIndicator = () => {
  const { staleNotifications } = useBrowserNotificationsStore();

  useEffect(() => {
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) return;

    if (staleNotifications.length) {
      link.href = '/app-notif.png';
    } else {
      link.href = '/app.png';
    }
  }, [staleNotifications]);
};

export { MainLayout };
