import React from 'react';
import { FaCopy } from 'react-icons/fa';
import { toast } from '../Toast/use-toast';
import { copyToClipboard } from 'src/common/utils/copyToClipboard';
import { Trans, useTranslation } from 'react-i18next';
import { getApiUrl } from 'src/common/utils';

interface CopyInputProps {
  header?: string;
  i18nKey?: string;
  subheader?: string | React.ReactElement;
  copyText?: string;
  subUrl?: string;
}

export const CopyInput: React.FC<CopyInputProps> = ({
  copyText,
  header = 'onboarding.channels.webhook.header',
  subheader,
  i18nKey,
  subUrl,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className='mb-[15px]'>
        <p
          className='header-3'
          style={{
            fontWeight: 600,
          }}
        >
          {t(header)}
        </p>
        {subheader ? (
          typeof subheader === 'string' ? (
            <p className='secondary !text-grey-500'>{subheader}</p>
          ) : i18nKey ? (
            <div className='!secondary !text-grey-500'>
              <Trans
                i18nKey={i18nKey}
                components={{
                  1: subheader,
                }}
              />
            </div>
          ) : null
        ) : null}
      </div>
      <div className='px-[15px] pt-[13px] pb-[15px] bg-light rounded-md secondary !text-grey-900 flex grid grid-cols-2 items-center items-center gap-[15px] h-[50px]'>
        <div className='col-start-1 col-end-11 truncate'>{`${
          copyText ? t(copyText) : `${getApiUrl()}/webhook`
        }${subUrl ? `/${subUrl}` : ''}`}</div>
        <div className='col-start-11 col-end-12'>
          <FaCopy
            role='button'
            className='w-[18px] h-[18px] text-grey-500'
            onClick={() => {
              copyToClipboard(
                `${copyText ? t(copyText) : `${getApiUrl()}/webhook`}${subUrl ? `/${subUrl}` : ''}`,
              );
              toast({
                variant: 'success',
                title: 'Text copied to your clipboard!',
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
