export const APP_SUBDOMAINS = ['app', 'stg','review-front','review'];

export const isPublicApp = () => {
  const { hostname } = window.location;

  const [subdomain, domainName, tld] = hostname.split('.');

  if (!domainName && hostname.startsWith('localhost')) {
    return false;
  } else if (subdomain && domainName === 'localhost') {
    return true;
  }

  const hasSubDomain = !!(subdomain && tld);

  return hasSubDomain && !APP_SUBDOMAINS.includes(subdomain);
};
