import React, { Dispatch, useCallback, useMemo, useState } from 'react';
import { FaPaperclip } from 'react-icons/fa';
import { ButtonProps, Button } from '../Button/Button';
import { FileWithPreview, MessengerType, UploadedFile } from 'src/common/interfaces';
import { useMutation } from '@tanstack/react-query';
import { uploadFiles } from 'src/common/api/file';
import { MethodEnum } from 'src/common/enums';
import { v4 as uuidv4 } from 'uuid';
import { sendAttachmentsMessage } from 'src/common/api/chat';
import { useParams } from 'react-router-dom';
import { useChatStore } from 'src/store/chat/chatStore';
import { useToast } from 'src/ui/Toast/use-toast';
import { useTranslation } from 'react-i18next';
import i18n from 'src/common/configs/i18n';

const supportedMediaAmount = {
  [MessengerType.WHATSAPP]: 1,
  [MessengerType.INSTAGRAM]: 1,
  [MessengerType.MESSENGER]: 1,
  [MessengerType.TELEGRAM]: 1,
};

const AttachmentsDialog = ({
  setIsAttachmentLoading,
  ...props
}: ButtonProps & {
  setIsAttachmentLoading?: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();

  const { chatId } = useParams();
  const { addNewMessage, activeChatInfo } = useChatStore();
  const { toast } = useToast();

  const [files, setFiles] = useState<FileWithPreview[] | null>(null);

  const { mutate: sendMessage } = useMutation({
    mutationFn: (data: { attachments: UploadedFile[]; body: string }) =>
      sendAttachmentsMessage({ chatId: chatId!, ...data }),
    onSuccess: message => {
      addNewMessage(message);
    },
    onSettled: () => setIsAttachmentLoading?.(false),
  });

  const { mutate } = useMutation({
    mutationFn: (data: { files: { file: File; key: string }[]; body: string }) =>
      uploadFiles(data.files, 'storage/upload/many', 'cors', MethodEnum.POST),
    onMutate: () => setIsAttachmentLoading?.(true),
    onSuccess: (data, variables) => {
      const attachments = data?.map(({ url, ...rest }) => ({ src: url, ...rest }));

      attachments &&
        sendMessage({
          attachments: attachments as unknown as UploadedFile[],
          body: variables.body,
        });

      if (!attachments) {
        setIsAttachmentLoading?.(false);
      }
    },
    onError: () => setIsAttachmentLoading?.(false),
  });

  const { maxFiles } = useMemo(() => {
    const messengerType = activeChatInfo?.messengerType;

    const maxFiles = messengerType ? supportedMediaAmount[messengerType] : 1;

    return { maxFiles };
  }, [activeChatInfo?.messengerType]);

  const handleFileChange = useCallback((event: any) => {
    const file = event.target?.files?.[0];

    if (file.size > 1024 * 1024 * 40) {
      toast({ title: t('common.fileSizeError'), variant: 'destructive' });
    } else {
      setFiles([file]);

      mutate({ files: [{ file, key: uuidv4() }], body: '' });
    }

    event.target.value = '';
    setFiles([]);
  }, []);

  return (
    <Button className='relative' disabled={props.disabled}>
      <div className='overflow-hidden w-[22px] h-[22px] relative cursor-pointer'>
        <FaPaperclip className='text-3xl text-grey cursor-pointer  w-[22px] h-[22px]' />

        <input
          className='absolute right-0 top-0 text-[1000px] opacity-0 cursor-pointer'
          type='file'
          disabled={!!(files && files.length >= maxFiles)}
          onChange={handleFileChange}
          multiple={false}
          name='files'
        />
      </div>
    </Button>
  );
};

export { AttachmentsDialog };
