import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "src/ui/DropdownMenu/DropdownMenu";
import { MenuBarProps, MobileMenuBar } from "src/ui/MenuBar/MenuBar";
import { LogoutButton } from "src/ui/LogoutButton/LogoutButton";
import { Avatar, AvatarFallback, AvatarImage } from "src/ui/Avatar/Avatar";
import { getUserInitials } from "src/common/utils/getUserInitials";
import { FaAddressCard, FaComments } from "react-icons/fa6";
import { FaMoneyBill, FaTachometerAlt } from "react-icons/fa";
import { ROUTES } from "src/router/routes";
import { useChatStore } from "src/store/chat/chatStore";
import { useOnboardingStatus } from "src/common/hooks";
import { cn } from "src/common/utils";

type UserMenuProps = Pick<MenuBarProps, "userImage" | "username" | "email"> & {
  avatarClassName?: string;
  children: React.ReactNode;
};

const UserMenu = ({ userImage, username, email, children }: UserMenuProps) => {
  const { requestsCount, inboxCount } = useChatStore();
  const { isCompleted } = useOnboardingStatus();

  const items = [
    {
      tPath: "menuBar.dashboard",
      icon: FaTachometerAlt,
      route: ROUTES.DASHBOARD,
    },
    {
      tPath: "menuBar.transactions",
      icon: FaMoneyBill,
      route: ROUTES.TRANSACTIONS,
    },
    {
      tPath: "menuBar.chats",
      icon: FaComments,
      route: ROUTES.CHATS,
      counter: requestsCount + inboxCount,
    },
    {
      tPath: "menuBar.myLinks",
      icon: FaAddressCard,
      route: ROUTES.LINKS,
    },
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Avatar className="w-[42px] h-[42px] md:w-[60px] md:h-[60px]">
          <AvatarImage
            src={userImage || undefined}
            className={"object-cover"}
          />
          <AvatarFallback>{getUserInitials(username)}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side="right"
        className="w-[100vw] md:h-fit md:max-w-[300px] sm:pb-[15px] left-0 -ml-[60px] md:ml-0 rounded-none sm:rounded-md shadow-none relative h-[calc(100%-96px)]"
        align="end"
      >
        <div
          className="flex flex-col justify-between overflow-y-scroll overflow-x-hidden md:overflow-y-auto lg:important-height-auto h-[100%]"
        >
          <div
            className={cn({
              "pb-[100px]": !isCompleted,
            })}
          >
            <div className="flex items-center px-[15px] md:px-[30px] py-[15px] md:py-[17px] gap-4 justify-between">
              <div className="flex items-center gap-[15px]">
                <Avatar className="w-[42px] h-[42px] md:hidden">
                  <AvatarImage
                    src={userImage || undefined}
                    className={"object-cover"}
                  />
                  <AvatarFallback>{getUserInitials(username)}</AvatarFallback>
                </Avatar>
                <div className="flex flex-col truncate">
                  <span className="truncate text-[14px] leading-[12px] font-semibold">
                    {username}
                  </span>
                  <span className="truncate text-grey-500 text-[12px] leading-[22px]">
                    {email}
                  </span>
                </div>
              </div>
              <LogoutButton mirror />
            </div>
            <DropdownMenuSeparator />
            {children}
          </div>
          {/*<MobileMenuBar*/}
          {/*  username={username}*/}
          {/*  navigationItems={items}*/}
          {/*  ignoreRoutes*/}
          {/*  className={cn('md:hidden sticky bottom-0', {*/}
          {/*    'mt-[15px] pt-[15px]': isCompleted,*/}
          {/*  })}*/}
          {/*/>*/}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { UserMenu };
