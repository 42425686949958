import React, { useState } from 'react';
import { MessengerType } from 'src/common/interfaces';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ExpertSettings, updateUserLocaly } from 'src/store/user/user.slice';
import { RemoveActiveChatsAlert, RemoveAlert } from './RemoveAlert';
import { checkActiveSessions, closeOnMessengerDelete } from 'src/common/api/chat';
import { FaSpinner } from 'react-icons/fa6';

type CommonRemoveAlertProps = {
  messengerType: MessengerType;
  isTheLast?: boolean;
};

type MessengerCreds = keyof Omit<ExpertSettings, 'id' | 'useAiHelper'>;

const getSettingsName = (messengerType: MessengerType): MessengerCreds => {
  switch (messengerType) {
    case MessengerType.INSTAGRAM:
      return 'instagramSettings';
    case MessengerType.MESSENGER:
      return 'messengerSettings';
    case MessengerType.WHATSAPP:
      return 'whatsappSettings';
    case MessengerType.TELEGRAM:
      return 'telegramSettings';
  }
};

const CommonRemoveAlert: React.FC<CommonRemoveAlertProps> = ({
  children,
  messengerType,
  isTheLast,
}) => {
  const [open, setIsOpen] = useState(false);
  const [isCloseWarnOpen, setIsCloseWarnOpen] = useState(false);
  const settings = getSettingsName(messengerType);

  const dispatch = useDispatch();

  const { data: amount, isLoading: isCheckingLoading } = useQuery({
    queryKey: [messengerType],
    queryFn: ({ queryKey }) => checkActiveSessions(queryKey[0]),
  });

  const { mutate: removeSettings, isLoading } = useMutation({
    mutationFn: () => closeOnMessengerDelete(messengerType),
    onSuccess: data => {
      dispatch(updateUserLocaly({ expert: { settings: { [settings]: data } } }));
    },
    onSettled: () => {
      setIsOpen(false);
    },
  });

  const handleOnRemove = () => {
    if (amount) {
      setIsOpen(false);
      setIsCloseWarnOpen(true);
    } else {
      removeSettings();
    }
  };

  const handleActiveSessions = () => {
    removeSettings();
    setIsCloseWarnOpen(false);
  };

  return (
    <>
      {isCheckingLoading ? (
        <FaSpinner className='animate-spin w-[35px] h-[35px] text-primary mr-2' />
      ) : (
        <RemoveAlert
          open={open}
          onOpenChange={setIsOpen}
          messengerType={messengerType}
          isLoading={isLoading}
          onRemove={handleOnRemove}
          isTheLast={isTheLast}
        >
          {children}
        </RemoveAlert>
      )}
      <RemoveActiveChatsAlert
        open={isCloseWarnOpen}
        onOpenChange={setIsCloseWarnOpen}
        messengerType={messengerType} //!
        onRemove={handleActiveSessions}
      />
    </>
  );
};

export { CommonRemoveAlert };
