import React, { useEffect, type RefObject, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { AppStateType } from 'src/store';
import { FaCircleInfo } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { CardPicker } from 'src/ui/CardPicker/CardPicker';
import { Controller } from 'react-hook-form';
import { FileInput } from 'src/ui/FileInput/FileInput';
import { BrandLogoPlaceholder } from 'src/components/BrandLogoPlaceholder';
import { FieldError } from 'src/components/FieldError';
import { businessCardSchema } from 'src/common/utils/schema/business-card.schema';
import * as z from 'zod';
import { useThematicTemplateStore } from 'src/store/expertise/useThematicTemplateStore';
import { UseMutateFunction } from '@tanstack/react-query';
import { UserInterface, updateUserLocaly } from 'src/store/user/user.slice';
import { customColorTemplateId, defaultThemplateId } from 'src/common/configs/thematic-templates';
import { ColorPicker } from 'src/ui/ColorPicker/ColorPicker';
import { CheckBox } from 'src/ui/CheckBox/CheckBox';

type Inputs = z.infer<typeof businessCardSchema>;

type BusinessCardFormProps = {
  formButtonRef: RefObject<HTMLButtonElement>;
  file: File | null | 'DELETED';
  setFile: React.Dispatch<React.SetStateAction<File | 'DELETED' | null>>;
  updateUserCard: UseMutateFunction<
    UserInterface,
    unknown,
    {
      token: string;
      brandLogo: File | 'DELETED' | null;
      vals: Inputs;
    },
    unknown
  >;
} & ReturnType<typeof useForm<Inputs>>;

const BusinessCardForm = ({
  formButtonRef,
  handleSubmit,
  reset,
  control,
  formState: { errors },
  file,
  setFile,
  updateUserCard,
  setValue,
}: BusinessCardFormProps) => {
  const { data: thematicTempates } = useThematicTemplateStore();
  const { user, userToken } = useSelector((state: AppStateType) => state.user);

  const { t } = useTranslation();

  const { thematicTemplate } = useWatch({ control });

  const cards = useMemo(() => {
    return [
      ...(thematicTempates?.sort((a, b) => a.thematicTemplateId - b.thematicTemplateId).map(({ thematicTemplateId, translation }) => ({
        value: thematicTemplateId,
        label: translation,
      })) ?? []),
      { value: customColorTemplateId, label: 'common.thematicTemplate.otherColor' },
    ];
  }, [thematicTempates]);

  useEffect(() => {
    const template = thematicTempates?.find(
      t => t.thematicTemplateId === user?.expert?.thematicTemplateConfig?.thematicTemplateId,
    );

    reset({
      thematicTemplate: {
        ...(template ? { ...template } : {}),
        thematicTemplateId:
          user?.expert?.thematicTemplateConfig?.thematicTemplateId || customColorTemplateId,
      },
      whiteBackground: user?.expert?.thematicTemplateConfig?.whiteBackground,
      brandLogoUrl: user?.expert?.brandLogoUrl,
      color: user?.expert?.thematicTemplateConfig?.color,
    });
  }, [user, thematicTempates]);

  if (!user || !userToken) return <></>;

  const onSubmit = (vals: Inputs) => {
    updateUserCard({ token: userToken!, brandLogo: file, vals });
  };

  return (
    <div>
      <div className='bg-[#FFF6E5] px-[20px] md:px-[30px] py-6 text-[14px]'>
        <Trans
          i18nKey={'businessCard.tip'}
          components={{
            1: <Link to={'/profile'} className='text-primary' />,
            2: <FaCircleInfo className='text-primary min-w-[16px] min-h-[16px] mt-[3px]' />,
            3: <div className='flex items-start gap-[10px]'/>
          }}
        />
      </div>
      <form className='flex flex-col px-8 my-6' onSubmit={handleSubmit(onSubmit)}>
        <h2 className='text-[18px] font-bold'>{t('businessCard.subtitle')}</h2>
        <div className='flex flex-col sm:flex-row gap-8 mt-[25px]'>
          <div className='sm:hidden'>
            <h3 className='font-semibold'>{t('businessCard.cover.title')}</h3>
            <p>{t('businessCard.cover.subtitle')}</p>
          </div>
          <Controller
            name='brandLogoUrl'
            control={control}
            render={({ field }) => {
              return (
                <FileInput
                  onChange={(e, url) => {
                    if (e.target.files?.[0]) setFile(e.target.files?.[0]);
                    field.onChange(url);
                  }}
                  onDelete={() => {
                    setFile('DELETED');
                    field.onChange(null);
                  }}
                  onCropperApply={(file, url) => {
                    setFile(file);
                    field.onChange(url);
                  }}
                  defaultUrl={field.value ?? undefined}
                  key={field.value}
                  cropperMode='rectangle'
                >
                  <BrandLogoPlaceholder />
                </FileInput>
              );
            }}
          />
          <div className='self-center hidden sm:block'>
            <h3 className='font-semibold'>{t('businessCard.cover.title')}</h3>
            <p>{t('businessCard.cover.subtitle')}</p>
          </div>
        </div>
        <FieldError error={errors.brandLogoUrl?.message} className='ml-2 mt-2' />
        {/*<div className='flex flex-col mt-[14px]'>*/}
        {/*  <Controller*/}
        {/*    name='thematicTemplate'*/}
        {/*    control={control}*/}
        {/*    render={({ field }) => (*/}
        {/*      <CardPicker*/}
        {/*        headerText='Thematic templates'*/}
        {/*        cards={cards}*/}
        {/*        value={field.value?.thematicTemplateId}*/}
        {/*        onChange={value => {*/}
        {/*          const newValue = thematicTempates?.find(*/}
        {/*            template => template.thematicTemplateId === value,*/}
        {/*          ) || {*/}
        {/*            thematicTemplateId: customColorTemplateId,*/}
        {/*          };*/}

        {/*          if ('logoUrl' in newValue) {*/}
        {/*            setValue('brandLogoUrl', newValue.logoUrl);*/}
        {/*          }*/}
        {/*          field.onChange(newValue);*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  />*/}
        {/*  {thematicTemplate?.thematicTemplateId !== defaultThemplateId && (*/}
        {/*    <Controller*/}
        {/*      name='whiteBackground'*/}
        {/*      control={control}*/}
        {/*      render={({ field }) => (*/}
        {/*        <div className='flex gap-2 items-center my-4'>*/}
        {/*          <CheckBox*/}
        {/*            id='whiteBackground'*/}
        {/*            checked={field.value}*/}
        {/*            onCheckedChange={val => field.onChange(val)}*/}
        {/*          />*/}
        {/*          <label htmlFor='whiteBackground' className='text-grey-900 text-[14px]'>*/}
        {/*            Use white background*/}
        {/*          </label>*/}
        {/*        </div>*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</div>*/}
        {/*{thematicTemplate?.thematicTemplateId === customColorTemplateId && (*/}
        {/*  <Controller*/}
        {/*    name='color'*/}
        {/*    control={control}*/}
        {/*    render={({ field }) => (*/}
        {/*      <ColorPicker*/}
        {/*        value={field.value}*/}
        {/*        onChange={field.onChange}*/}
        {/*        colors={[*/}
        {/*          '#A4262C',*/}
        {/*          '#ED780D',*/}
        {/*          '#867365',*/}
        {/*          '#407855',*/}
        {/*          '#038387',*/}
        {/*          '#0078D4',*/}
        {/*          '#40587C',*/}
        {/*          '#8764B8',*/}
        {/*        ]}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  />*/}
        {/*)}*/}
        <FieldError error={errors.thematicTemplate?.message} className='ml-2 mt-2' />
        <button className='hidden' ref={formButtonRef} />
      </form>
    </div>
  );
};

export { BusinessCardForm };
