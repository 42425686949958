import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  authUser,
  getUserViaToken,
  updateUser,
  verifyOTP,
  updateLogos,
  updateViaAssistant,
  updateUserProfile,
  updateBusinessCard,
  updateOffer,
  getSubscriptions,
  updateSubscriptions,
  updatePaymentSettings,
} from './user.actions';
import { AppStateType } from 'src/store';
import { ExpertiseInterface } from '../onboarding/onboarding.slice';
import { ExpertSubscriptionTypeEnum } from 'src/common/enums/expert-plan-type.enum';
import { ThematicTemplateConfig } from 'src/common/interfaces/thematicTemplate';
import { StorageKeysEnum } from 'src/common/enums';
import { RecursivePartial } from 'src/common/interfaces';

export interface ExpertSubscription {
  expertPlanId: number;
  amount: number;
  isEnabled: boolean;
  price: null | number;
  description: string;
  type: ExpertSubscriptionTypeEnum;
}

export interface PaymentSettingsInterface {
  id: number;
  accountNumber: string;
  routingNumber: string;
  taxID: string;
  city: string;
  addrLine1: string;
  addrLine2: string;
  postCode: string;
  state: string;
  phoneNumber: string;
  birthday: string;
  stripeId: string;
  bankToken: string;
  useCommonAccount: boolean | null;
}

export interface OfferInterface {
  id: number;
  url: string;
  isTemplate: boolean;
}

export interface ExpertInterface {
  userId: number;
  brandName?: string;
  brandLogoUrl?: string | null;
  description?: string;
  brandScheme?: string;
  thematicTemplateConfigId?: number;
  thematicTemplateConfig?: ThematicTemplateConfig;
  settings?: ExpertSettings;
  expertiseId?: number;
  domainName?: string;
  expertise?: ExpertiseInterface | null;
  offer?: OfferInterface | null;
  paymentSettings?: PaymentSettingsInterface;
}

export type ExpertSettings = {
  id: number;
  useAiHelper: boolean;
  whatsappSettings: WhatsappSettings | null;
  messengerSettings: MessengerSettings | null;
  instagramSettings: InstagramSettings | null;
  telegramSettings: TelegramSettings | null;
};

export type InstagramSettings = {
  id: number;
  pageAccessToken?: string | null;
  pageId?: string | null;
  recipientId?: string | null;
  username?: string | null;
};

export type MessengerSettings = {
  id: number;
  pageAccessToken?: string | null;
  pageId?: string | null;
};

export type WhatsappSettings = {
  id: number;
  phoneNumberId: string | null;
  accessToken: string | null;
  phone: string | null;
};

export type TelegramSettings = {
  id: number;
  botToken: string | null;
  botName: string | null;
};

export interface UserInterface {
  id: number;
  firstName?: string;
  lastName?: string;
  email: string;
  phone?: string;
  avatarUrl?: string | null;
  role: 'expert' | 'admin';
  secret?: string;
  hadUserOnboarding: boolean;
  expert: ExpertInterface | null;
  admin: {} | null;
}

interface InitialState {
  loading: boolean;
  isSaving?: boolean;
  user: UserInterface | null;
  userToken?: string;
  expertSubscriptions: ExpertSubscription[];
  error: string | null;
  success: boolean;
  reject_code : string | null;
}

const initialState: InitialState = {
  loading: false,
  isSaving: false,
  user: null,
  userToken: localStorage.getItem(StorageKeysEnum.TOKEN) || undefined,
  expertSubscriptions: [],
  error: null,
  success: false,
  reject_code : null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: () => {
      localStorage.clear();
      return {
        ...initialState,
        userToken: undefined,
      };
    },
    updateSubscriptionsLocaly: (state, action: PayloadAction<ExpertSubscription[]>) => {
      state.expertSubscriptions = action.payload;
    },
    updateUserLocaly: (
      state,
      action: PayloadAction<RecursivePartial<UserInterface>>,
    ): InitialState => {
      const { expert, ...rest } = action.payload;

      const newSettingsData = {
        ...state.user?.expert?.settings,
        ...expert?.settings,
      };

      const newExpertData = {
        ...state.user?.expert,
        ...expert,
        settings: newSettingsData,
      } as ExpertInterface;

      return {
        ...state,
        user: {
          ...state.user,
          ...rest,
          expert: newExpertData,
        } as UserInterface,
      };
    },
  },
  extraReducers: builder => {
    // --------------- AUTH --------------
    builder.addCase(authUser.pending, state => {
      state.loading = true;
      state.error = null;
      state.reject_code = null;
    });
    builder.addCase(authUser.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.reject_code = action.payload?.reject_code;
      state.user = {
        id: -1,
        role: 'expert',
        email: action.payload.email,
        hadUserOnboarding: false,
        admin: null,
        expert: null,
      };
    });
    builder.addCase(authUser.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Request failed...';
      state.reject_code = null;
    });

    // ------------ GET USER --------------
    builder.addCase(getUserViaToken.pending, state => {
      state.loading = true;
      state.error = null;
      state.reject_code = null;
    });
    builder.addCase(getUserViaToken.fulfilled, (state, action) => {
      state.loading = false;
      state.userToken = action.payload?.access_token;
      state.user = action.payload?.user;
      state.success = true;
      state.reject_code = null;
      if (state.userToken) {
        localStorage.setItem(StorageKeysEnum.TOKEN, state.userToken as string);
      }
    });
    builder.addCase(getUserViaToken.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Request failed...';
    });

    // ----------- VERIFY OTP --------------
    builder.addCase(verifyOTP.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(verifyOTP.fulfilled, (state, action) => {
      state.loading = false;
      state.userToken = action.payload?.access_token;
      state.user = action.payload?.user;
      state.success = true;
      state.reject_code = null;
      if (state.userToken) {
        localStorage.setItem(StorageKeysEnum.TOKEN, state.userToken as string);
      }
    });
    builder.addCase(verifyOTP.rejected, (state, action) => {
      if (localStorage.getItem(StorageKeysEnum.TOKEN)) {
        localStorage.removeItem(StorageKeysEnum.TOKEN);
      }

      return {
        error: 'Incorrect code',
        loading: false,
        success: false,
        reject_code : null,
        user: state.user,
        userToken: '',
        expertSubscriptions: [],
      };
    });

    //---------------- UPDATE USER -------------------
    builder.addCase(updateUser.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload?.user;
      state.success = true;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Update failed...';
    });

    //---------------- UPDATE USER PROFILE -------------------
    builder.addCase(updateUserProfile.pending, state => {
      state.loading = true;
      state.isSaving = true;
      state.error = null;
    });
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.isSaving = false;
      state.userToken = action.payload?.access_token;
      state.user = action.payload?.user;
      state.success = true;
    });
    builder.addCase(updateUserProfile.rejected, (state, action) => {
      state.loading = false;
      state.isSaving = false;
      state.error = 'Update failed...';
    });

    //----------- UPDATE USER VIA ASSISTANT ------------
    builder.addCase(updateViaAssistant.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateViaAssistant.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.success = true;
    });
    builder.addCase(updateViaAssistant.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Update failed...';
    });

    //---------------- UPDATE AVATAR -------------------
    builder.addCase(updateLogos.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateLogos.fulfilled, (state, action) => {
      state.loading = false;
      state.userToken = action.payload?.access_token;
      state.user = {
        ...state.user!,
        avatarUrl:
          action.payload?.avatarUrl === undefined
            ? state.user?.avatarUrl
            : action.payload?.avatarUrl,
        expert: {
          ...state.user?.expert!,
          brandLogoUrl:
            action.payload?.brandLogoUrl === undefined
              ? state.user?.expert?.brandLogoUrl
              : action.payload?.brandLogoUrl,
        },
      };
      state.success = true;
    });
    builder.addCase(updateLogos.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Update avatar failed...';
    });

    //--------------------- UPDATE OFFER ----------------------

    builder.addCase(updateOffer.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateOffer.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.success = true;
    });
    builder.addCase(updateOffer.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Update avatar failed...';
    });

    //---------------- UPDATE BUSINESS CARD -------------------
    builder.addCase(updateBusinessCard.pending, state => {
      state.loading = true;
      state.isSaving = true;
      state.error = null;
    });
    builder.addCase(updateBusinessCard.fulfilled, (state, action) => {
      state.loading = false;
      state.isSaving = false;
      state.userToken = action.payload?.access_token;
      state.user = action.payload?.user;
      state.success = true;
    });
    builder.addCase(updateBusinessCard.rejected, (state, action) => {
      state.loading = false;
      state.isSaving = false;
      state.error = 'Update business card failed...';
    });

    //--------------------- GET SUBSCRIPTIONS --------------------------
    builder.addCase(getSubscriptions.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSubscriptions.fulfilled, (state, action) => {
      state.loading = false;
      state.expertSubscriptions = action.payload?.subscriptions;
      state.success = true;
    });
    builder.addCase(getSubscriptions.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Plans receiving failed...';
    });

    //--------------------- UPDATE SUBSCRIPTIONS ------------------------
    builder.addCase(updateSubscriptions.pending, state => {
      state.isSaving = true;
      state.error = null;
    });
    builder.addCase(updateSubscriptions.fulfilled, (state, action) => {
      state.isSaving = false;
      state.expertSubscriptions = action.payload?.subscriptions;
      state.error = null;
    });
    builder.addCase(updateSubscriptions.rejected, state => {
      state.isSaving = false;
      state.error = 'Plans updating failed...';
    });

    //----------------------- PAYMENT SETTINGS -----------------------------

    builder.addCase(updatePaymentSettings.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updatePaymentSettings.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload?.user;
      state.success = true;
    });
    builder.addCase(updatePaymentSettings.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Updating failed';
    });
  },
});

export const { reset, updateUserLocaly, updateSubscriptionsLocaly } = userSlice.actions;
export const selectUser = (state: AppStateType) => state.user.user;
export default userSlice.reducer;
