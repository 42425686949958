import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSpinner, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MessengerType } from 'src/common/interfaces';
import { cn } from 'src/common/utils/cn';
import { extendedMessengerName } from 'src/common/utils/extendMessengerName';
import { ROUTES } from 'src/router/routes';
import { selectUser, updateUserLocaly } from 'src/store/user/user.slice';
import { Button } from 'src/ui/Button/Button';
import { MessengerIcon } from 'src/ui/MessengerIcon/MessengerIcon';
import { PageWrapper } from '../Common/Page.wrapper';
import { useTelegram } from 'src/common/hooks/useTelegram';
import { CommonRemoveAlert } from './Alerts/CommonAlert';

const items = [
  {
    type: MessengerType.WHATSAPP,
    subtitleKey: 'communicationChannels.whatsapp.description',
    to: ROUTES.WHATSAPP_SETTINGS,
  },
  {
    type: MessengerType.MESSENGER,
    subtitleKey: 'communicationChannels.messenger.description',
    to: ROUTES.MESSENGER_SETTINGS,
  },
  {
    type: MessengerType.INSTAGRAM,
    subtitleKey: 'communicationChannels.instagram.description',
    to: ROUTES.INSTAGRAM_SETTINGS,
  },
  {
    type: MessengerType.TELEGRAM,
    subtitleKey: 'communicationChannels.telegram.description',
    to: ROUTES.TELEGRAM_SETTINSG,
  },
];

export default function CommunicationChannelsPage() {
  const user = useSelector(selectUser);

  const { t } = useTranslation();
  const { isLoading, saveTelegramSettings } = useTelegram();

  const connectedChannels = useMemo(() => {
    return {
      [MessengerType.WHATSAPP]: !!user?.expert?.settings?.whatsappSettings?.phone,
      [MessengerType.MESSENGER]: !!user?.expert?.settings?.messengerSettings?.pageId,
      [MessengerType.INSTAGRAM]: !!user?.expert?.settings?.instagramSettings?.username,
      [MessengerType.TELEGRAM]: !!user?.expert?.settings?.telegramSettings?.botToken,
    };
  }, [user]);

  const activeCounter = useMemo(() => {
    return Object.values(connectedChannels).reduce((sum, channel) => (sum += channel ? 1 : 0), 0);
  }, [connectedChannels]);

  return (
    <PageWrapper headerText={t('communicationChannels.title')}>
      <h2 className='text-center mt-[15px] md:mt-0 md:text-left text-[16px] font-400'>
        {t('communicationChannels.subtitle')}
      </h2>
      <section className='mt-[25px] md:my-20 flex flex-col gap-4 items-center justify-center'>
        {items.map(item => {
          const isWhatsapp = item.type === MessengerType.WHATSAPP;
          const isMessenger = item.type === MessengerType.MESSENGER;
          const isInstagram = item.type === MessengerType.INSTAGRAM;
          const isTelegram = item.type === MessengerType.TELEGRAM;

          const isConnected = connectedChannels[item.type];

          return (
            <div
              className='p-6 rounded-md bg-white flex flex-col items-center md:flex-row text-center md:text-start gap-6 w-full max-w-[800px]'
              key={item.type}
            >
              <MessengerIcon messengerType={item.type} className='w-12 h-12' />
              <div className='flex flex-col'>
                <span className='font-bold'>{extendedMessengerName[item.type]}</span>
                <span className='text-grey-500'>{t(item.subtitleKey)}</span>
              </div>
              {!isConnected ? (
                <Button
                  variant={'primary'}
                  className={cn('md:ml-auto font-semibold', {
                    'bg-instagram': isInstagram,
                    'bg-messenger': isMessenger,
                    'bg-whatsapp': isWhatsapp,
                  })}
                  asChild
                  disabled={isTelegram && isLoading}
                >
                  {!isTelegram ? (
                    <Link to={item.to}>{t('communicationChannels.connect')}</Link>
                  ) : (
                    <div
                      onClick={() => saveTelegramSettings()}
                      className='cursor-pointer flex items-center'
                    >
                      {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
                      {t('communicationChannels.connect')}
                    </div>
                  )}
                </Button>
              ) : (
                <div className='flex gap-2 md:ml-auto'>
                  {!isTelegram ? (
                    <Button
                      variant={'outlined'}
                      className={cn('font-semibold hover:opacity-80')}
                      asChild
                    >
                      <Link to={item.to}>{t('communicationChannels.settings')}</Link>
                    </Button>
                  ) : null}
                  <RemoveChannelAlert messengerType={item.type} isTheLast={activeCounter === 1}>
                    <Button variant={'muted'} className={cn('font-semibold')}>
                      <FaTimes className='w-4 h-4' />
                    </Button>
                  </RemoveChannelAlert>
                </div>
              )}
            </div>
          );
        })}
      </section>
    </PageWrapper>
  );
}

const RemoveChannelAlert: React.FC<{ messengerType: MessengerType; isTheLast?: boolean }> = ({
  children,
  messengerType,
  isTheLast,
}) => {
  return (
    <CommonRemoveAlert isTheLast={isTheLast} messengerType={messengerType}>
      {children}
    </CommonRemoveAlert>
  );
};
