import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button/Button';

import { FaSpinner, FaXmark } from 'react-icons/fa6';
import { FaCheck as CheckIcon } from 'react-icons/fa6';
import { useMutation } from '@tanstack/react-query';
import { approveChatRequest, rejectChatRequest } from 'src/common/api/chat';
import { useChatStore } from 'src/store/chat/chatStore';
import { Dialog, DialogContent, DialogTrigger } from '../Dialog/Dialog';
import { Textarea } from '../TextInput/TextInput';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { FieldError } from 'src/components/FieldError';
import { useActiveChat } from 'src/store/chat/useActiveChat';
import { useSearchParams } from 'src/common/hooks';
import { ChatStatus } from 'src/common/interfaces';

const schema = z.object({
  message: z
    .string({
      errorMap: () => ({
        message: 'Reason message is required',
      }),
    })
    .min(5, 'Reason message is too short')
    .max(257, 'Reason message is too long'),
});

type Inputs = z.infer<typeof schema>;

const ActionButtons = ({ chatId }: { chatId: string }) => {
  const { t } = useTranslation();

  const { handleChatApprove, handleChatReject } = useChatStore();

  const { refetch: refetchActiveChat } = useActiveChat(chatId);
  const { setNewSearchParams } = useSearchParams();

  const { mutate: rejectRequest, isLoading: isRejecting } = useMutation({
    mutationFn: rejectChatRequest,
    onSuccess: message => {
      handleChatReject(chatId, message);
      refetchActiveChat();
      setIsOpen(false);
      setNewSearchParams({ chatStatus: ChatStatus.CLOSED });
    },
  });

  const { mutate: approveRequest, isLoading: isApproving } = useMutation({
    mutationFn: approveChatRequest,
    onSuccess: message => {
      handleChatApprove(chatId, message);
      refetchActiveChat();
      setNewSearchParams({ chatStatus: ChatStatus.ACTIVE });
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
    defaultValues: {
      message: '',
    },
  });

  const { message } = useWatch({ control });

  const [open, setIsOpen] = useState(false);

  const onSubmit = (vals: Inputs) => {
    rejectRequest({ chatId, message: vals.message });
  };

  const isActionLoading = isApproving || isRejecting;

  return (
    <div className='flex flex-col sm:flex-row gap-2 w-full mt-4'>
      <Button
        variant={'success'}
        className='w-full font-semibold'
        onClick={() => approveRequest(chatId)}
        disabled={isActionLoading}
      >
        {isApproving ? (
          <FaSpinner className='animate-spin w-5 h-5 text-white mr-2' />
        ) : (
          <CheckIcon className='w-5 h-5 text-white mr-2' />
        )}
        {t('common.approve')}
      </Button>
      <Dialog open={open} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button
            variant={'muted'}
            className='w-full text-black font-semibold hover:text-black'
            disabled={isActionLoading}
          >
            <FaXmark className='w-[16px] h-[16px]' />
            {t('common.reject')}
          </Button>
        </DialogTrigger>
        <DialogContent className='max-w-[420px] rounded-md border-none'>
          <h1 className='text-grey-900 text-center text-2xl font-bold'>
            {t('chats.clientRequest.rejectModal.title')}
          </h1>
          <form onSubmit={handleSubmit(onSubmit)} className='space-y-6 mt-[23px]'>
            <Controller
              control={control}
              name='message'
              render={({ field }) => (
                <Textarea
                  error={errors.message?.message}
                  rows={8}
                  id='reject-description'
                  placeholder={t('chats.clientRequest.rejectModal.placeholder')}
                  {...field}
                />
              )}
            />
            <Button
              variant={'primary'}
              className='w-full'
              disabled={
                Number(message?.length) < 5 || Number(message?.length) > 257 || isActionLoading
              }
            >
              {isRejecting && <FaSpinner className='animate-spin w-5 h-5 mr-2' />}
              {t('common.send')}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export { ActionButtons };
