import { useEffect, useState } from 'react';

export const useMobileDetect = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const isSmallMobile = width <= 420;

  return {
    isMobile,
    isSmallMobile,
    width
  };
};
