import { MessengerType } from '../interfaces';

export const saveToSessionStorage = (key: string, value: string) => {
  return sessionStorage.setItem(key, value);
};

export const getFromSessionStorage = (key: string) => {
  return sessionStorage.getItem(key);
};

export const saveChatToStorage = (chatId: string, value?: string) => {
  sessionStorage.clear();
  saveToSessionStorage(chatId, value ?? '');
};

export const getFromChatStorage = (chatId: string) => {
  const value = getFromSessionStorage(chatId);
  return value;
};

export const removeFromSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
}

export const saveToStorageOnBlur = (
  event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>,
  key: MessengerType,
) => {
  const { name, value } = event.target;

  const values = getFromSessionStorage(key);
  if (values) {
    const data = JSON.parse(values);
    data[name] = value;
    saveToSessionStorage(key, JSON.stringify(data));
  } else {
    saveToSessionStorage(
      key,
      JSON.stringify({
        [name]: value,
      }),
    );
  }
};

export const getObjectFromStorage = <T>(key: string): Partial<T> | undefined => {
  const data = getFromSessionStorage(key);
  return data ? JSON.parse(data) : undefined;
};
