import { useDispatch } from 'react-redux';
import { useToast } from 'src/ui/Toast/use-toast';
import { setTelegramSettings } from '../api/channels-settings';
import { updateUserLocaly } from 'src/store/user/user.slice';
import { nextStep } from 'src/store/onboarding/onboarding.slice';
import { useMutation } from '@tanstack/react-query';
import { catchError } from '../utils/catchError';

export const useTelegram = () => {
  const dispatch = useDispatch();
  const { toast } = useToast();

  const { mutate: saveTelegramSettings, isLoading } = useMutation({
    mutationFn: async () => {
      return setTelegramSettings();
    },
    onSuccess: data => {
      toast({ title: 'Bot was successfully created!', variant: 'success' });
      dispatch(updateUserLocaly({ expert: { settings: { telegramSettings: data } } }));
      dispatch(nextStep(1));
    },
    onError: e => {
      catchError(e, undefined, 'Telegram connection is failed! ');
    },
  });

  return {
    saveTelegramSettings,
    isLoading,
  };
};
