import React from 'react';
import { InstagramTutorial } from '../Onboarding/Subpages/Connection/Tutorials/InstagramTutorial';
import { Button } from 'src/ui/Button/Button';
import { FaChevronLeft } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { TutorialPageWrapper } from './TutorialPageWrapper';

export default function InstagramTutorialPage() {
  const navigate = useNavigate();

  return (
    <TutorialPageWrapper backFunction={() => navigate(-1)}>
      <InstagramTutorial>
        <Button onClick={() => navigate(-1)}>
          <FaChevronLeft className='w-6 h-6 text-muted ' />
        </Button>
      </InstagramTutorial>
    </TutorialPageWrapper>
  );
}
