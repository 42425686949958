interface Element {
  [key: string]: any;
}

export function extractFromArray<T extends Element>(
  array: T[] | undefined,
  value: string,
  key: keyof T,
) {
  if (!array) {
    return { value: null, newArray: [] };
  }

  const index = array.findIndex(elem => elem[key] === value);

  if (index === -1) {
    return { value: null, newArray: array };
  }

  const extractedValue = array.splice(index, 1)[0];

  return { value: extractedValue, newArray: array };
}
